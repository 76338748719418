.retail {
  &-header {
    margin-bottom: 64px
  }

  &-product {
    padding: 40px 26px 46px;
    margin-bottom: 30px;
  }

  &-patient {
    padding: 40px 26px 46px;
    margin-bottom: 30px;
    display: flex;

    &__form {
      @include respond('xs') {
        width: 100%;
      }

      width: 65%;
    }

  }
}
