.rc-time-picker {
  display: block;
  width: 100%;

  &-clear {
    top: 9px;
    right: -5px;
  }

  &-input {
    border: 0;
    padding: 1rem 0 0.5rem;
    height: auto;

    font-family: $main-font;
    font-size: $default-font-size;
    color: color(dark);
  }
}
