.audit {
  @include respond('as') {
    padding: 15px 20px;
    min-height: 100vh;
  }

  @include respond('xs') {
    min-height: 140vh;
  }

  padding: 20px 30px;

  &__header {
    margin-bottom: 0.625rem;

    &-title {
      margin-bottom: 5px;
      //text-transform: uppercase;
      font-size: 24px;
    }

    &-filter {
      @include respond('md-lg') {
        .filter-form {
          width: 85%;
        }

        .filter-button {
          width: 15%;
          height: 80px;
        }
      }

      @include respond('as') {
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;

        .filter-form {
          margin-right: 0 !important;
          margin-bottom: 10px;
          width: 100% !important;
        }
      }

      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;

      .filter-form {
        margin-right: 20px;
        width: 90%;
      }

      .filter-button {
        .btn {
          @include respond('as') {
            height: 60%;
            font-size: 16px;
          }

          min-width: 5.000rem !important;
          height: 3.875rem;
        }
      }
    }
  }

  &__main {
    position: relative;

    .settings__empty {
      margin: 9.375rem 0 0;
    }
  }
}

.audit_responsive {
  min-height: auto;
  height: auto;
}
