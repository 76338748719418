.fancy-scroll::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
  opacity: 0.8;
}

.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #43667626;
}
