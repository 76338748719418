.procedure {
  &-heading {
    border-bottom: 1px solid rgba(67, 102, 118, 0.148353);
  }

  &-attachment {
    border: 1.5px dashed rgba(67, 102, 118, 0.148353);
    border-radius: 4px;
    padding: 12px 14px;
    display: block;

    &__not-dashed {
      border: 0.5px solid rgba(67, 102, 118, 0.148353);
      padding: 12px 14px;
      border-radius: 4px;

    }

    &__btn {
      color: theme(primary);
    }
  }

  &-add-btn {
    background: transparent;
    border: none;
    outline: none;

    & p {
      margin-bottom: 0;
    }
  }
}
