.toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  max-width: max-content;
  height: 100%;

  &__option {
    @include font-size(small);
    @include font-weight(semi-bold);
    margin: 0;
    letter-spacing: 1px;
    color: color(dark);
    transition: all 0.3s;

    &--left {
      margin-right: 1.094rem;
    }

    &--right {
      margin-left: 1.094rem;
    }
  }
}

$width: 30px;
$height: $width / 3;

.toggler {
  position: relative;
  display: inline-block;
  margin: (($height * 1.4 - $height) / 2) + 5px;
  margin: (($height * 1.5 - $height) / 2) - 5px;
  width: $width;
  height: $height;
  text-align: center;

  input[type='checkbox'] {
    display: none;

    &:checked ~ .switch__button {
      left: ($width - ($height * 1.4 - $height) / 2) - $height;
      background: color(white);
      transition: 0.5s;
    }

    &:checked ~ .switch {
      background: darken(color(purple), 1);
      transition: 0.5s;
    }

    &:checked ~ .is-checked {
      background: #12d60e !important;
      transition: 0.5s;
    }
  }

  .switch {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: $height / 2;
    width: $width;
    height: $height;
    background: theme(primary);
    cursor: pointer;
    transition: 0.5s;

    &__button {
      position: absolute;
      top: ($height * 1.4 - $height) / -2;
      left: ($height * 1.4 - $height) / -2;
      box-shadow: 0 $width / 50 $width / 25 rgba(black, 0.4);
      border-radius: 50%;
      width: $height * 1.4;
      height: $height * 1.4;
      background: color(white);
      cursor: pointer;
      transition: 0.5s;
    }
  }

  .is-checked {
    background: #a6a2a2 !important;
  }
}

.clientSwitch {
  + .switch {
    background: theme(primary);
  }

  &:checked + .switch {
    background: theme(primary);
  }

  ~ .toggle {
    background: #fff !important;
  }
}
