.view-order {
  // background: red;

  &-floater {
    position: absolute;
    top: 34px;
    right: 40px;

    &.created {
      top: 0;
      right: 0;
      text-align: right;
    }
  }
}
