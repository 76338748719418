.new_date_range_picker {
  .picker {
    margin-bottom: 1.875rem;
    border-radius: 4px;
    border: 1px solid rgba(67, 102, 118, 0.15);
    padding: 0.313rem 0.938rem;
    background-color: #fff;
    transition: box-shadow 0.2s;
    height: 63.8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9b9b9b;
    overflow: hidden;

    .clear_button {
      padding: 5px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: none;
      background-color: red;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      bottom: calc(50% - 10px);
    }

    .label_column {
      height: 100%;
      .label {
        letter-spacing: 0.5px;
        font-size: 0.750rem;
        align-self: flex-start;
      }
      .value {
        color: black;
        font-size: 0.938rem;
      }
    }

    .icon {
      font-size: 0.875rem;
      color: hsl(0, 0%, 80%);
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }

  .range_filters {
    z-index: 4;
    position: absolute;
    left: 0;
    top: 40px;
    box-shadow: 0 5px 15px #e4eaf6;
    border-radius: 5px;
    border: 1px solid rgba(67, 102, 118, 0.15);
    padding: 20px;
    width: 500px;
    background: #fff;
    -webkit-animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    .date_input {
      position: static;
    }
  }
}
