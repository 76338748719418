.quick-book {
  &__group {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    width: 100%;
    height: 100%;
    background: color(white);

    .header {
      border-bottom: 1px solid rgba(67, 102, 118, 0.148353);
      padding: 1.250rem;
      padding-bottom: 1.000rem;

      &-title {
        line-height: 1.563rem;
        font-size: 1.250rem;
        color: color(dark);
      }
    }

    .body {
      padding: 1.250rem 0.875rem;
      max-height: 50.000rem;
      overflow-y: auto;
    }
  }

  &__item {
    @include flex-display(row);
    margin-bottom: 1.250rem;
    box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
    border-radius: 7px;
    border: 1px solid rgba(67, 102, 118, 0.148353);
    padding: 0.938rem;
    width: 100%;
    background: color(white);

    .avatar {
      @include round-image(50px, cover);
    }

    .content {
      flex: 1;
      margin: 0 0.938rem;
    }

    .book-button {
      border-bottom: 1px solid theme(primary);
      padding-bottom: 0.156rem;
      cursor: pointer;
      font-family: Source Sans Pro;
      color: theme(primary);
      transition: transform 0.25s linear;

      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}
