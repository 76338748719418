.patients-admission table.hmo__table {
  thead {
    background-color: #6a69e412 !important;
  }
}

table.hmo__table {
  input[type='checkbox'] {
    height: 20px;
    width: 20px;
    border: 1.5px solid blue;
  }
  thead {
    background-color: #6a69e412 !important;
  }
  tbody tr {
    background-color: white !important;
  }
  tr:first-child {
    th:first-child.nopadding {
      padding: 0 0 0 20px !important;
    }
  }
  td:first-child.nopadding {
    padding: 0 0 0 20px !important;
  }

  tr {
    border-bottom: 0.5px solid #ccccff99;
  }
}
