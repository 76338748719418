.frontdesk {
    &__stat-card {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
        border-radius: 7px;
        padding: 1.250rem;
        background: color(white);

        .stat-section {
            @include flex-display(row);
            justify-content: space-between;
            margin: 0.750rem 0;
            line-height: 32px;
            font-size: 25px;
            color: theme(primary);
        }
    }

    &__appointment-group {
        position: relative;
        margin-bottom: 1.563rem;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 1.250rem;
        width: 100%;
        overflow: hidden;
        background: color(white);

        .header {
            @include flex-display(row);
        }

        .content {
            margin: 1.875rem 0 1.250rem;
            min-height: 34.375rem;
            max-height: 46.875rem;
            overflow-x: hidden;
            overflow-y: scroll;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .footer {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.12);

            a {
                color: inherit;
            }

            &-button {
                margin: 0 auto;
                padding: 0.625rem 0;
                max-width: 6.250rem;
                cursor: pointer;
                text-align: center;
                color: theme(primary);
                transition: transform 0.25s linear;

                &:hover {
                  transform: translateY(-3px);
                }
            }
        }
    }

    &__checkin-btn {
        @include font-size(small);
        @include font-weight(medium);
        border-radius: 10.313rem;
        border: 1px solid theme(primary);
        padding: 0.500rem 1.125rem;
        background: color(white);
        cursor: pointer;
        text-align: center;
        text-transform: uppercase;
        line-height: 0.938rem;
        color: theme(primary);
    }
}
