.drug-chart-item {
  @include respond(rl-) {
    display: block;
  }
  display: flex;
  flex-grow: 1;
  margin-bottom: 27px;
  box-shadow: 0 1px 12px rgba(35, 35, 44, 0.12);

  &-drug {
    @include respond(rl-) {
      max-width: 100%;
    }
    box-shadow: 0 1px 12px rgba(35, 35, 44, 0.12);
    border-radius: 4px;
    //padding: 30px 50px 30px 30px;
    max-width: 306px;
    background: color(drugBox);

    h1 {
      margin-bottom: 10px;
      color: color(primary);
    }

    b {
      display: block;
      margin-bottom: 3px;
      color: color(grey);
    }

    span {
      display: flex;

      p:last-child {
        margin-left: 20px;
        color: color(dark-green) !important;
      }
    }

    button {
      margin-top: 15px;
      border-radius: 4px;
      border: 0;
      padding: 10px 20px;
      background: color(primary);
      font-size: 14px;
      color: white;
    }
  }

  &-days {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 40px;
    background: white;

    &-entry {
      @include respond(as) {
        display: block;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 50px;
      padding-bottom: 15px;
      text-align: left;

      &:not(:first-child) {
        @include respond(rl-) {
          padding-top: 20px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #00000026;
      }

      p {
        margin: 0;
      }

      &-left {
        width: 33.3%;

        i {
          margin: 0 30px;
        }

        div {
          margin-left: 77px;
        }

        p {
          margin-right: 10px;
        }
      }

      &-dosage {
        @include respond(as) {
          display: block;
          margin-top: 15px;
          margin-left: 76px;
        }
        display: flex;
        flex-grow: 1;
        margin-left: 20px;
        text-align: left;

        p {
          @include respond(as) {
            width: 100%;
            text-align: left;
          }
          width: 50%;
          text-align: center;
        }

        div {
          @include respond(as) {
            width: 100%;
            text-align: left;
          }
          width: 50%;
          text-align: end;

          span:first-child {
            @include respond(am) {
              display: none;
            }
            @include respond(as) {
              display: unset;
            }
          }
        }
      }
    }
  }

  &-table {
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 20px;
    }

    tr:first-child {
      th {
        padding-bottom: 15px;
      }
    }

    td {
      padding: 18px 0;
      background: #fafafe;
    }

    td:first-child,
    th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-left: 15px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      padding-left: 15px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .stat {
      background: unset !important;
      text-transform: capitalize;
    }

    p {
      white-space: nowrap;
      word-break: keep-all;
    }
  }
}
