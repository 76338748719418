.user__profile {
  display: flex;
  @extend .user__profile-gap;

  &-gap {
    gap: 35px;
  }

  & > * {
    flex: 1;
  }

  &-card {
    background: #ffffff;
    border: 1px solid rgba(67, 102, 118, 0.148353);
    border-radius: 10px;
    padding: 40px 35px;
  }

  &-pic {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      content: 'Change Picture';
      color: #ffffff;
      bottom: 0;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      width: 100%;
      height: 35%;
      background: rgba(0, 0, 0, 0.7);
      transition: 0.6s all cubic-bezier(0.83, 0, 0.17, 1);
      transform: translateY(100%);
    }

    &:hover::after {
      transform: translateY(0%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-submit-btn {
    display: flex;
    justify-content: flex-end;
  }

  &-inputs {
    & > * > * {
      border: 1.5px solid rgba(67, 102, 118, 0.148353);
      border-radius: 4px;
      width: 50px;
      height: 50px;
      outline: none;
      padding: 20px 15px;
      text-align: center;
    }
  }
}
