$placeholder: rgba(74, 74, 74, 0.4);

input[type='tel'] {
  border: 0;
  width: 100%;
}

.form {
  width: 100%;

  &-area {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
    height: unset;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: color(dark);
    transition: border 0.2s, box-shadow 0.2s;
    border: 1px solid rgba(67, 102, 118, 0.15);
    border-radius: 4px;
    padding: 0.313rem 0.938rem;
    background-color: #fff;
    position: relative;
    margin-bottom: 1.875rem;

    label {
      display: block;
      letter-spacing: 0.5px;
      font-size: 0.750rem;
      color: #9b9b9b;
    }

    textarea {
      border: none;
      display: block;
      width: 100%;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: $placeholder;
      }
    }
  }

  &-box {
    line-height: 1.6;

    input {
      display: none;
    }

    label {
      display: inline-flex;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;

      .box {
        display: inline-block;
        margin-right: 0.313rem;
        border-radius: 2px;
        border: 1px solid rgba(color(grey), 0.3);
        width: 0.813rem;
        height: 0.813rem;
        transition: border-color 0.2s, background-color 0.2s;
      }

      .is-checked {
        border-color: theme(secondary);
        background-color: theme(secondary);
      }

      .text {
        letter-spacing: 1px;
        font-size: 0.750rem;
        color: color(grey);
        user-select: none;
      }
    }
  }

  &-radio {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1.875rem;

    .container {
      position: relative;
      display: block;
      padding-right: 0;
      padding-left: 30px;
      cursor: pointer;
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      color: color(dark);
      user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;

      input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
      }

      .radio-button {
        position: absolute;
        top: 3px;
        left: 0;
        border-radius: 50%;
        border: 2px solid rgba(67, 102, 118, 0.148353);
        width: 0.813rem;
        height: 0.813rem;
        background-color: #fff;
        transition: border-color 0.3s, background 0.3s;

        &__left {
          left: 0;
        }
      }

      &:hover input ~ .radio-button {
        background-color: color(light-grey);
      }

      input:checked ~ .radio-button {
        background: theme(primary);
      }
    }
  }

  &-control {
    &--comment {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      border: 0;
      padding: 0.625rem;
      height: unset;
      background-color: rgba(68, 157, 249, 0.03);
      font-size: 0.750rem;

      &::placeholder {
        color: $placeholder;
      }
    }

    &--user-comments {
      margin-top: 1.250rem;
      color: color(dark);

      .title {
        margin-bottom: 10px;
        text-transform: capitalize;
      }

      .comment {
        max-height: 18.750rem;
        overflow: auto;

        &-body {
          padding: 0.313rem;
          border-radius: 7px;
          background: color(light-blue);
          margin-bottom: 15px;

          &__header {
            .date {
              color: color(grey);
              font-size: get-font-size(x-small);
            }
          }

          &__footer {
            color: color(grey);

            a {
              font-size: 0.875rem;
              font-style: italic;
              color: orange;
            }
          }
        }
      }
    }
  }

  &__field {
    margin-bottom: 0;
    box-shadow: none;
    border: 0;
    padding: 0;
    background: transparent;
    letter-spacing: 0.5px;
    font-size: 0.875rem;
    font-weight: 400;
    color: color(dark);
    transition: border 0.2s, box-shadow 0.2s;

    &::placeholder {
      color: $placeholder;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: 0;
    }

    &:disabled {
      background: transparent;
    }

    &--select {
      option {
        color: color(dark) !important;
      }

      option[value=''] {
        color: $placeholder;
      }

      [disabled] {
        color: $placeholder;
      }

      &:invalid {
        color: $placeholder;
      }
    }
  }

  &__password {
    display: flex;
    align-items: center;
  }

  &-group {
    position: relative;
    margin-bottom: 1.875rem;
    border-radius: 4px;
    border: 1px solid rgba(67, 102, 118, 0.15);
    padding: 0.313rem 0.938rem;
    background-color: color(white);
    transition: box-shadow 0.2s;
    $this: &;

    &--purple {
      border: 1px solid #6a69e4;

      & .modal-select__placeholder,
      & .modal-select__single-value {
        color: #6a69e4 !important;
        font-weight: 700 !important;
        font-size: 18px;
      }

      & .modal-select__placeholder {
        color: #6a69e4 !important;
      }

      & .modal-select__single-value {
        color: #6a69e4 !important;
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    &:focus-within {
      box-shadow: 0 0 1px 1px rgba(color(black), 0.1);
    }

    &.is-invalid {
      border-left: 0;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 3px;
        height: 100%;
        background: theme(secondary);
        content: '';
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0.313rem 0.938rem;
        height: 100%;
        background: color(white);
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: theme(secondary);
        content: '\f071';
      }
    }

    &.no-margin {
      margin: 0;
    }
  }

  &__label {
    display: block;
    letter-spacing: 0.5px;
    font-size: 0.750rem;
    color: color(grey);
  }

  &__phone {
    display: flex;
    margin-bottom: 0.313rem;

    &-code {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 0.625rem;
      padding-right: 0.625rem;
      color: theme(primary);

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 50%;
        background: color(grey);
        transform: translateY(-50%);
        content: '';
      }
    }
  }

  &-select {
    &__placeholder {
      font-size: 0.750rem;
    }

    &__indicator-separator {
      display: none;
    }
  }

  &__select {
    .modal-select {
      &__control {
        border: 0;
        padding: 0;
        height: unset;
        min-height: calc(1.250em + 2px);
        letter-spacing: 0.5px;
        font-size: 0.875rem;
        font-weight: 400;

        &--is-focused,
        &--is-focused:hover {
          box-shadow: none !important;
          border: 0 !important;
        }

        &--is-disabled {
          background: transparent !important;

          .modal-select__indicators {
            display: none !important;
          }
        }
      }

      &__indicator {
        padding: 0;
      }

      &__multi-value {
        border-radius: 30px;
        padding: 0 0.313rem;
        background: rgba(106, 105, 228, 0.2);

        &__remove {
          svg {
            color: theme(secondary);
          }
        }
      }

      &__placeholder {
        margin: 0;
        letter-spacing: 0.5px;
        font-weight: 400;
        color: $placeholder;
      }

      &__value-container {
        padding: 0;
      }
    }
  }

  &__settings {
    &-button {
      flex: 0 0 50%;
      margin: 0 auto;
      width: 100%;

      @include respond('lg') {
        max-width: 50%;
      }

      &.minimal {
        flex: 0 0 186px;
        margin: unset;
        max-width: 186px;
      }

      .btn {
        margin: 0 auto;
        width: 100%;
        height: 3.125rem;
        @include respond('md') {
          max-width: 90%;
        }
      }
    }

    &-footer {
      margin-top: 0.625rem;
      margin-right: auto;
      margin-left: auto;
    }

    &-group {
      margin-bottom: 1.875rem;
    }

    .add-value {
      @include font-size(regular);
      margin-top: -10px;
      padding-left: 5px;
      width: 6.250rem;
      cursor: pointer;
      text-transform: capitalize;
      color: theme(primary);
    }

    .value-list {
      display: flex;
      flex-wrap: wrap;
      margin: 10px auto;
      padding: 10px 0;
      width: 95%;

      &__item {
        display: flex;
        margin: 10px 5px;
        border-radius: 10px;
        padding: 0.313rem 0.625rem;
        background: theme(primary);
        text-transform: capitalize;
        color: color(white);

        span:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  &__suggestions {
    position: relative;

    &-btn {
      position: absolute;
      top: 0;
      right: 0;

      & .btn-sm {
        width: auto;
        padding: 20px;
        height: auto;
        background-color: var(--primary-color);
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .suggestion {
      padding: 0.625rem;
      cursor: pointer;
      font-size: 0.875rem;
      color: color(dark);

      &-display {
        cursor: pointer;

        &:hover {
          background: color(primary) !important;
          opacity: 0.3;

          &::after {
            color: color(white);
            content: ' (Items has no stock or has expired)';
          }
        }
      }

      &-disabled {
        cursor: no-drop;

        &:hover {
          background: color(primary) !important;
          opacity: 0.3;
        }
      }

      &:hover {
        background: theme(primary);
        color: color(white);
      }

      &s,
      &s-frontdesk {
        z-index: 100;
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        border-radius: 5px;
        border: 1px solid rgba(67, 102, 118, 0.15);
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: color(white);
        list-style: none;
      }

      &s-reroute {
        border-top: 1px solid rgba(67, 102, 118, 0.15);
        padding: 0.625rem 0;
        width: 100%;
        background: #ffffff;
        text-align: center;
      }

      &s-frontdesk {
        box-shadow: 0 5px 15px rgba(21, 21, 25, 0.08);
        border-radius: 10px;
        border: 0;
        max-height: 250px;
        background: #ffffff;

        .suggestion {
          @include flex-display(row);
          justify-content: flex-start;
          margin: 1.250rem 0;
          padding: 0 1.250rem;
          cursor: pointer;
          line-height: 18px;
          font-size: 14px;

          &:hover {
            background: transparent;
            color: inherit;

            span {
              background: color(white);
              color: theme(primary);
            }
          }
        }
      }
    }
  }
}

.react-tel-input {
  font-family: $main-font;

  ::-webkit-input-placeholder {
    /* Edge */
    font-family: $main-font;
    font-weight: 400;
    color: rgba(color(dark), 0.4);
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: $main-font;
    font-weight: 400;
    color: rgba(color(dark), 0.4);
  }

  ::placeholder {
    font-family: $main-font;
    font-weight: 400;
    color: $placeholder;
  }
}

.searchWrapper {
  border: none !important;
}

li.groupHeading {
  color: color(indigo);
  text-transform: capitalize;
}

.chip {
  background: color(light-purple);
  color: color(indigo);
  border-radius: 5px;
}
