// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900');

// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------


// iconmoon
@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/icomoon/icomoon.eot?xxewj5#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon/icomoon.ttf?xxewj5') format('truetype'),
  url('../../fonts/icomoon/icomoon.woff?xxewj5') format('woff'),
  url('../../fonts/icomoon/icomoon.svg?xxewj5#icomoon') format('svg');
  font-display: block;
}


