.webcam-modal {
  &-window {
    margin: 0 auto;
    border-radius: 10px;
    border: 1px dashed #6a69e4;
    background: rgba(106, 105, 228, 0.1);
    object-fit: cover;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &-control {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
