@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes deadline {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes filter-option {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes nav-options {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes notifier {
  0% {
    transform: translateY(-20rem);
    -webkit-transform: translateY(-20rem);
  }

  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
