.performance {
  &-amount {
    font-style: normal;
    line-height: 40px;
    text-align: center;
    color: #4A4A4A;

    &--left {
      text-align: left;
    }
  }
}
