.items-nav {
  @include respond('as') {
    ul {
      flex-grow: 1;
      justify-content: space-around;
    }

    .dashboard-header-link-text {
      display: none;
    }
  }
  display: flex;
  justify-content: space-between;

  .dashboard-header-link {
    margin-bottom: -13px;
  }
}

.page-tabs {
  .nav-tabs {
    border-bottom: 0;
  }

  .nav-link {
    @include font-size(medium);
    @include font-weight(medium);
    margin-right: 2.188rem;
    border-radius: 0;
    border: 0;
    padding: 0.313rem 0;
    color: color(grey);
    transition: 0.25s ease-in color;

    &:hover {
      background-color: transparent;
      color: theme(primary);
    }

    &.active {
      border: 0;
      border-bottom: 5px solid theme(primary);
      background-color: transparent;
      color: theme(primary);
    }
  }
}
