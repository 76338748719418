.daily-bill-item {
    display: flex;
    flex-direction: column;
    margin: 10px 1%;
    width: 48%;

    h1 {
        @include font-size(large);
        padding: 20px 0;
        color: color(primary);
    }

    div:last-child {
        margin-top: auto !important;
    }

}
