.notification-card-2 {
  z-index: 10;
  display: flex;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1.5px solid rgba(67, 102, 118, 0.148353);
  width: 420px;
  background: #b22222ff;
  color: white;
  transition: right 2s;

  &-active {
    right: 38px;
  }

  &-message {
    flex-grow: 1;
    position: relative;
    border-right: 1.5px solid rgba(67, 102, 118, 0.148353);
    padding: 20px;

    &-title {
      line-height: 150%;
      font-size: 16px;
      font-weight: 500;
    }

    &-text {
      line-height: 150%;
      font-size: 14px;
    }

    &__tip {
      z-index: 5;
      position: absolute;
      top: 18px;
      right: 10px;
      color: whitesmoke;
    }
  }

  &-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .accept {
      color: #32327c;
    }
  }
}
