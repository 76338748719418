.settings {
  position: relative;
  margin: 1.250rem 0;
  border-radius: 3px;
  border: 1px solid color(light-grey);
  width: 100%;
  min-height: 80vh;
  background: color(white);

  .filter {
    @include respond('xs') {
      margin-left: 0;
    }

    margin: 1.250rem;
  }

  &__table-actions {
    padding: 50px;
  }

  &__button {
    @include respond('sm') {
      padding-right: 0;
      padding-left: 1.250rem;
    }

    @include respond('xs') {
      padding-right: 0;
      padding-left: 0;
    }

    padding-right: 1.250rem;
    height: 100%;

    .btn {
      @include respond('sm') {
        margin: 0.938rem 0;
        margin-top: 0;
      }

      @include respond('xs') {
        margin: 0.938rem 0;
        margin-top: 0;
      }

      margin-left: auto;
      color: color(dark);
    }
  }

  &__empty {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
    color: color(grey);
    transform: translate(-50%, -50%);

    &-content {
      @include flex-display(column);
      height: 100%;
    }

    &-img {
      margin-bottom: 1.250rem;
      width: max-content;

      svg {
        max-width: 15.625rem;
        max-height: 15.625rem;
      }
    }

    &-text {
      text-align: center;
      text-transform: capitalize;
      font-size: 20px;
    }

    &-footer {
      @include respond('as') {
        flex-direction: column;
        align-items: center;
      }

      display: flex;

      .btn {
        margin: 5px 10px;
      }
    }

    &-onboarding {
      @include abs-center();
    }
  }

  &__main {
    &-row {
      @include respond('as') {
        overflow-x: auto;
      }

      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
    }
  }

  &__nav {
    @include respond('sm') {
      justify-content: space-between;
    }

    @include respond('xs') {
      justify-content: space-between;
    }

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-bottom: 1px solid color(light-grey);
    width: 100%;
    list-style: none;

    &-item {
      @include respond('sm') {
        width: 50%;
      }

      @include respond('xs') {
        width: 50%;
      }

      position: relative;
    }

    &-link {
      display: block;
      padding: 0.938rem 2.500rem;
      text-transform: capitalize;
      font-size: 1.000rem;
      font-weight: 400;
      color: color(grey);

      &:hover,
      &.active {
        text-decoration: none;
        color: theme(primary);

        &::after {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 4px;
          background: theme(primary);
          content: '';
        }
      }
    }
  }
}

.filter-action {
  @include flex-display(row);
  position: relative;
  padding: 0.313rem 1.875rem;
  height: 2.813rem;

  svg,
  &__title {
    cursor: pointer;
  }

  &__title {
    margin-left: 10px;
    text-transform: uppercase;
    font-size: get-font-size(small);
  }

  .options {
    z-index: 10;
    position: relative;
    box-shadow: 0 5px 15px #e4eaf6;
    border-radius: 4px;
    padding: 5px 10px;
    background: #fff;
    list-style: none;
    animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    li {
      overflow: hidden;
      cursor: pointer;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: color(grey);

      &:hover,
      &:active {
        @include nav-border-bottom(theme(primary));
        color: theme(primary);
      }
    }

    &-primary {
      position: absolute;
      top: 1.875rem;
      left: 0;
      max-width: 6.875rem;
    }

    &-secondary {
      position: absolute;
      top: 3.750rem;
      right: 7.500rem;
      max-width: 9.375rem;
      max-height: 15.625rem;
      overflow: auto;
    }
  }

  &-custom {
    &-staff-box {
      margin-left: auto;
      width: max-content;
    }

    &-staff {
      @include respond('as') {
        width: 100%;
      }

      z-index: 4;
      position: absolute;
      right: 0;
      margin-top: 20px;
      box-shadow: 0 5px 15px #e4eaf6;
      border-radius: 5px;
      border: 1px solid color(light-grey);
      padding: 20px;
      width: 600px;
      background: color(white);
      animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      &.appointment_group_filter {
        top: 6px;
        @include appointment_filter_tablet_responsive;
      }

      &.patient_filter {
        @include filter_tablet_responsive;
      }
      &.reportcard_filter {
        @include reportcard_tablet_responsive;
      }

      &.inpatient_group_filter {
        top: 25px;
      }

      &.filter_returns {
        @include respond('md') {
          left: -350px;
        }
      }

      .arrow {
        position: absolute;
        right: 0;
        display: block;
        width: 0.500rem;
        height: 0.250rem;

        &::after {
          @include filter_arrow_tablet_responsive;
          if &.appointment_group_filter {
            @include appointment_arrow_filter_tablet_responsive;
          }

          if &.reportcard_filter {
            @include reportcard_arrow_tablet_responsive;
          }

          position: absolute;
          top: -35px;
          right: 30px;
          // box-shadow: 0 1px 15px 0 #e6eaec;
          border-right: 30px solid transparent;
          border-bottom: 30px solid color(white);
          border-left: 30px solid transparent;
          width: 0;
          height: 0;
          transform: rotate(0deg);
          content: '';
        }
      }

      .clear-date {
        position: absolute;
        top: 20px;
        right: 32px;
        color: color(very-light-grey);
      }

      .DayPickerInput input::placeholder {
        color: rgba(74, 74, 74, 0.4);
      }

      .date-input i {
        display: none;
      }

      &.product {
        @include respond(xl) {
          right: 365px;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          right: 73%;
          display: block;
          width: 400px;

          .row {
            display: block;
            margin-right: unset;
            margin-left: unset;
            padding-right: unset;
            padding-left: unset;
            width: 100%;
          }

          div {
            max-width: 100%;
          }
        }
      }

      &.two-cols {
        @media only screen and (min-width: 1200px) {
          margin-right: 10px;
          padding-bottom: 5px;
          width: 400px;
        }

        .two-col-button {
          margin: auto;
        }
      }
    }

    &-staff .form-group {
      margin-bottom: 1.563rem;
      border-color: color(white);
      border-bottom-color: color(light-grey);
    }

    &-staff .btn {
      @include respond('as') {
        margin: auto;
      }
      margin-bottom: 1.000rem;
      margin-left: auto;
      width: max-content;
    }
  }
}

.full-toast-notifier {
  position: absolute;
  right: 0.625rem;
  min-width: 43.750rem;
  background: rgba(color(watermelon), 0.3);
  color: rgba(color(watermelon), 0.7);
}

.w-full {
  width: 100%;
}

.filter_container_responsive {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  @include filter_container_tablet_responsive;
}

.filter_order_container {
  width: 100%;
  @include respond('ab') {
    width: fit-content;
  }
}
