.nocontent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  height: 100%;

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-title {
    @include font-size(large);
    line-height: 32px;
  }

  &-subtitle {
    @include font-size(medium);
    margin-bottom: 1.250rem;
    line-height: 23px;
  }

  &-iconbox {
    @include border-radius(50px);
    @include font-size(x-large);
    width: 100px;
    height: 100px;
    background-color: #6a69e44d;
    color: color(indigo);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
