:root {
  --primary-color: #6a69e4;
  --primary-color-shade: rgba(106, 105, 228, 0.07);
  --secondary-color: #fa4659;
  --secondary-color-shade: rgba(250, 70, 89, 0.15);
  --report-table-header-color: #6a69e4;
}

$themeColors: (
        primary: var(--primary-color),
        primaryShade: var(--primary-color-shade),
        secondary: var(--secondary-color),
        secondaryShade: var(--secondary-color-shade),
);

$colors: (
        dark: #4a4a4a,
        dark-72: #4a4a4ab8,
        dark-purple: #32327c,
        grey: #9b9b9b,
        grey-2: #8c8cb41a,
        grey-3: #43667626,
        grey-4: #949494,
        grey-5: rgba(106, 105, 228, 0.08),
        grey-6: rgba(74, 74, 74, 0.50),
        light-grey: rgba(67, 102, 118, 0.15),
        very-light-grey: #cccccc,
        off-purple: rgba(106, 105, 228, 0.22),
        light-blue: #f7f7fa,
        lighter-blue: #f8f8ff,
        deep-grey:rgba(106, 105, 228, 0.1),
        white: #fff,
        off-white: #f6f9fc,
        grey-white: #f9f9ff,
        white-70: #ffffffb2,
        black: #000,
        yellow: #f5a623,
        yellow-2: #e8a869,
        green: #50e3c2,
        dark-green: #58c9b9,
        watermelon: #fa4659,
        light-watermelon: #e1b9bd,
        purple: #6a69e4,
        light-purple: #6a69e412,
        light-purple-2: #dfdff4,
        indigo: #5655d0,
        cyan: #53c7f0,
        lavender-blue: #8483fe,
        active: #32327c,
        onboarding-tabs: #ccccff,
        darkBorder: #4a4a4a66,
        context-menu-item: #4d4d4d,
        primary: var(--primary-color),
        red: #fa4659,
        dark-red: #d0021b,
        drugBox: #f1f1fc,
);

$spaceamounts: (0, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

$system-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
'Helvetica Neue', sans-serif;
$main-font: 'Inter', sans-serif;
$default-font-size: 16px;

$font-sizes: (
        xx-small: 10px,
        x-small: 12px,
        small: 14px,
        regular: 16px,
        medium: 18px,
        medium-large: 20px,
        large: 24px,
        m-large: 35px,
        x-large: 40px,
        xx-large: 70px,
);

$font-weights: (
        extra-light: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semi-bold: 600,
        bold: 700,
        black: 900,
);

$status-background: (
        pending: rgba(245, 166, 35, 0.1),
        declined: rgba(27, 26, 26, 0.15),
        approved: rgba(80, 227, 194, 0.1),
        quantity: rgba(106, 105, 228, 0.07),
        active: rgba(105, 105, 228, 0.1),
        completed: rgba(245, 165, 35, 0.15),
        delivered: rgba(80, 227, 194, 0.149),
        accepted: #53c7f026,
);

$status-color: (
        declined: rgba(250, 70, 89, 1),
        approed: rgba(80, 227, 194, 1),
        pending: rgba(245, 166, 35, 1),
        quantity: rgba(106, 105, 228, 1),
        active: rgb(104, 104, 227),
        completed: rgb(245, 166, 35),
        delivered: rgb(80, 227, 194),
        accepted: #53c7f0,
        administered: #50e3c2,
);

$box-shadows: (
        primary: 0px 5px 15px rgba(21, 21, 25, 0.08),
);

$border-radius: (4, 8, 10, 16);
