.drug-chart-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
        display: flex;
        align-items: center;

        p {
            @include font-size(medium);
            margin-right: 79px;
            margin-bottom: 0;
            color: color(primary);
        }

    }

    &-view-selector {
        display: flex;
        border-radius: 4px;
        border: 1px solid color(primary);
        transition: 0.2s ease;

        p {
            @include font-size(small);
            margin: 0;
            padding: 8px 40px;

            &.active {
                background: color(primary);
                color: white;
            }

        }

    }

}
