.context-menu {
  position: relative;

  &-icon {
    // width: 20px;
    cursor: pointer;
    text-align: center;
    color: #d8d8d8;

    &:hover {
      color: theme(secondary) !important;
    }
  }

  &-body {
    z-index: 1;
    position: absolute;
    right: calc(100% - 10px);
    box-shadow: 0 4px 20px 0 rgba(21, 21, 25, 0.08);
    border-radius: 4px;
    padding: 10px;
    background: color(white);
    list-style: none;
    color: color(context-menu-item);

    i {
      margin-right: 5px;
    }

    li {
      @include font-size(x-small);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      &:hover {
        color: theme(secondary) !important;

        span::before {
          color: theme(secondary) !important;
        }
      }

      span {
        margin-right: 10px;
      }
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }

    li:last-child {
      margin-bottom: 1px;
    }
  }

  &-product {
    right: -9px !important;
    width: max-content !important;
  }

  .more-options &-body {
    right: 15px;
    margin-top: 5px;
    padding: 0 20px;
    width: 86%;
    animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    li {
      @include font-size(small);
      margin: 20px auto;
    }
  }

  ul.right-0 {
    left: 0;
    min-width: 148px;
  }

}
