.aggrement {
  height: 23.750rem;
  text-align: justify;
  color: color(dark);
  font-size: 18px;
  padding: 20px 15px 30px;
  margin: 10px 0;

  ol {
    list-style-position: inside;
    margin-bottom: 8px;

    li {
      margin-bottom: 10px;
    }

    &.alpha {
      list-style-type: upper-alpha;
    }

    &.roman {
      list-style-type: lower-roman;
    }
  }

  p {
    margin-bottom: 15px;
  }


}
