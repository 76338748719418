.support {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-banner {
    box-sizing: border-box;
    flex: auto;
    background: #6A69E4;
    padding: 15px 0 5px;
    color: #fff;

    &-header {
      padding-bottom: 65px;

      .description {
        width: 83%;
      }
    }
  }

  &-feedback {
    flex: 2;
    padding: 40px 0;
    background: #fff;


  }
}

.wrapper {
  @include respond('xl') {
    max-width: 1300px;
  }

  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr)
}
