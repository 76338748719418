.proforma-invoice {
  &-table {
    tbody tr td {
      padding: 5px;
    }
    .proforma-invoice__input {
      width: 128px;
      border: 1px solid rgba(67, 102, 118, 0.148353);
      outline: none;
      padding: 2px;
    }
  }
}
