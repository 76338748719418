.left-tab-layout {
  display: flex;
  flex-grow: 1;
  border-radius: 7px;
  background: white;

  &-tabs {
    margin-right: 20px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px 0 15px;
      box-shadow: 0 2px 4px rgba(67, 102, 118, 0.148353);
      border: 1px solid rgba(67, 102, 118, 0.148353);
      width: 125px;
      height: 103px;
      color: var(--primary-color);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      i {
        font-size: 30px;
      }

      p {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0;
      }

      &.active {
        border: 1px solid rgba(67, 102, 118, 0.14);
        background: rgba(106, 105, 228, 0.0974864);
      }
    }
  }

  &-content {
    flex-grow: 1;
    border: 3px solid #e2e2f9;
    padding: 1.875rem;
    max-width: calc(100% - 120px);

    &-item {
      width: 0;
      height: 0;
      overflow: hidden;

      &.active {
        width: unset;
        height: unset;
        overflow: unset;
      }

      &-header {
        @include font-size(medium);
        color: var(--primary-color);
      }
    }
  }
}
