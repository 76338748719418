.frontdesk-body .calendar {
  .context-menu-body {
    right: unset;
    left: 0;
    width: 100%;
    min-width: 150px;
  }

  .context-menu li {
    @include font-size(small);
    padding: 5px 0;
  }

  .context-menu-icon {
    padding: 10px;
  }

  .nocontent {
    margin-top: 0;
    width: 100%;
    min-height: 50vh;
  }

  .shift-time {
    display: block;
    margin: 5px 0;
    border-radius: 2px;
    padding: 10px;
    width: max-content;
    min-width: 115px;
    background-color: rgba($color: color(purple), $alpha: 0.1);
    color: color(purple);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba($color: color(purple), $alpha: 0.3);
    }
  }
}

.frontdesk-body .calendar {
  &__details {
    &--upcoming-appointment {
      background-color: theme(primary);
      color: color(white);
      border-radius: 8px;
      padding: 10px;
      margin-top: 12px;
      width: 300px;
      position: absolute;
      z-index: 1;
      top: 10;
    }
    &--upcoming-appointment-2 {
      @include border-radius(8px);
      border-radius: 8px;
      padding: 10px;
      margin-top: 12px;
      position: relative;
      border: 0.5px solid rgba(0, 0, 0, 0.12);

      ::before {
        content: '';
        position: absolute;
        width: 5px;
        background-color: theme(primary);
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }
}
