// sass-lint:disable class-name-format
.DayPickerInput {
  font-size: 16px !important;

  .form-control[readonly] {
    background: transparent !important;
    cursor: pointer;
  }

  select {
    border: 0;
  }
}

.DayPickerInput-OverlayWrapper {
  z-index: 3;
}
