.doughnut {
    position: relative;
    width: 100%;
    height: max-content;

    &-chart {
      @include respond(as) {
        height: 420px;
      }

        position: relative;
        display: flex;
        flex-direction: row-reverse;
        min-width: unset;
        min-height: unset;

        canvas {
          margin: auto;
          width: 100%;
          max-width: 481px !important;
          max-height: 591px !important;
        }

        &-counter {
          @include respond(md) {
            span {
              font-size: 8px;
            }
          }

          @include respond(lg) {
            span {
              @include font-size(x-small);
            }
          }


          position: absolute;
          top: 53%;
          width: 100%;
          text-align: center;
          font-weight: bold;
        }
      }
      .counter {
        position: absolute;
        top: 55%;
        left: 45%;
      }
  }
