.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(67, 102, 118, 0.15);
  padding: 0.938rem;
  background-color: color(white);
  position: relative;

  &__appointment-state {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__frontdesk-appointment {
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(21, 21, 25, 0.08);
    padding: 0;

    .flag {
      @include flex-display(row);
      position: absolute;
      top: -1.250rem;
      box-shadow: 0 5px 15px rgba(21, 21, 25, 0.08);
      border-radius: 88px;
      border: 0.5px solid #f5a623;
      padding: 0 0.625rem;
      min-width: 7.500rem;
      max-width: max-content;
      min-height: 2.188rem;
      background: color(white);
      color: theme(primary);
    }

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(67, 102, 118, 0.15);
      padding: 1.250rem;
      width: 100%;

      &-avatar {
        @include round-image(40px, cover);
      }

      &-title {
        @include font-weight(medium);
        @include font-size(regular);
        @include text-wrap();
        margin-right: auto;
        margin-left: 0.625rem;
        line-height: 20px;
        letter-spacing: -0.019em;
        color: color(dark);
      }

      &-status {
        border-radius: 7px;
        padding: 0.313rem 0.938rem;
        min-width: 5.625rem;
        max-width: max-content;
        height: 1.875rem;
        background: #bfe8fb;
        text-align: center;
        text-transform: capitalize;
        font-size: 0.813rem;
        font-weight: normal;
        color: #2498ce;
        user-select: none;
        white-space: nowrap;

        &:active,
        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        &__active {
          background: #f8c36b;
          color: #925b00;
        }

        &__done {
          background: #bfe8fb;
          color: #2498ce;
        }

        &__scheduled {
          background: rgba(106, 105, 228, 0.3);
          color: #6a69e4;
        }

        &__canceled,
        &__no-show {
          background: #ff7473;
          color: color(white);
        }
      }
    }

    .main {
      @include flex-display(row);
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1.250rem;
      width: 100%;

      &-info {
        &__title {
          margin-bottom: 0.313rem;
          line-height: 20px;
          font-size: 0.875rem;
          color: #B8C4CE;
          font-weight: 500;
        }

        &__subtitle {
          margin-bottom: 0;
          text-transform: capitalize;
          line-height: 20px;
          font-size: 0.875rem;
          color: color(dark);
        }
      }
    }
  }

  &__inpatient-discharge {
    .header {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid rgba(67, 102, 118, 0.15);
      padding: 1.063rem;
      width: 100%;

      &-admission {
        @include font-size(medium);
        @include text-wrap();
        flex: 1;
        margin-top: -0.156rem;
        margin-right: 0.938rem;
        margin-left: 0;
        line-height: 32px;
        letter-spacing: -0.019em;
        color: color(dark);
      }

      &-status {
        border-radius: 16px;
        padding: 0.313rem 0.938rem;
        height: 1.563rem;
        background: #6a69e4;
        text-transform: uppercase;
        font-size: 0.750rem;
        color: color(white);
        user-select: none;

        &:active,
        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        &__discharged {
          background: #50e3c2;
          color: color(white);
        }

        &__cancelled {
          background: #fa4659;
          color: color(white);
        }
      }
    }

    .footer {
      border-top: 1px solid rgba(67, 102, 118, 0.15);
      padding: 1.063rem;
      width: 100%;
    }
  }

  &__registration {
    flex-direction: column;
    border-radius: 4px;
    padding: 0;
    max-height: 55px;
    overflow: hidden;
    transition: 0.5s max-height;

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(67, 102, 118, 0.15);
      padding: 10px 20px;
      width: 100%;
      cursor: pointer;
      color: color(grey);

      h2 {
        @include font-size(medium);
        text-transform: capitalize;
        color: color(dark);
      }

      .title-icon {
        @include font-size('large');
        color: theme(primary);
      }

      .settings-icon {
        margin-left: auto;
        transition: 0.5s transform;
      }
    }

    .main {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px 30px;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        margin: 5px 10px;
        border-radius: 20px;
        border: 1px solid rgba(67, 102, 118, 0.15);
        padding: 22px 10px;
        height: 30px;
        cursor: pointer;

        &__text {
          padding: 0 5px;
          text-transform: capitalize;
        }

        &__input {
          position: relative;
          margin-top: 5px;
          margin-left: 10px;
          overflow: hidden;
          opacity: 0;
          transition: 0.5s opacity;

          .checker {
            position: relative;
            display: block;
            margin-bottom: 2px;
            border-radius: 50%;
            width: 1.250rem;
            height: 1.250rem;
            background: theme(primary);
            color: color(white);

            i {
              position: absolute;
              top: 25%;
              left: 25%;
              font-size: 10px;
            }
          }
        }

        .checked {
          opacity: 1;
        }
      }
    }
  }

  &__medic-standard {
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 1.875rem 1.250rem 0;
    min-height: 135px;
    text-decoration: none !important;
    text-transform: capitalize;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(0.925);
    }

    &-title {
      line-height: 23px;
      font-size: get-font-size(medium);
      font-weight: get-font-weight(medium);
      color: color(dark);
    }

    &-subtitle {
      line-height: 20px;
      font-size: get-font-size(x-small);
      color: color(grey);
    }
  }

  &__hospital {
    flex-direction: column;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0;

    .header {
      padding: 20px;
      width: 100%;
      background: rgba(196, 196, 196, 0.11);

      &-title {
        margin-bottom: 5px;
        text-transform: capitalize;
        font-size: get-font-size(medium);
      }

      &-subtitle {
        font-size: get-font-size(x-small);
        color: color(grey);
      }
    }

    .body {
      @include flex-display(column);
      padding: 20px;
      width: 100%;

      .hospital {
        &-logo {
          @include round-image(9.375rem, cover);
          margin-top: 15px;
        }

        &-upload {
          position: relative;
          margin: 10px 0 50px;
          text-align: center;

          h2 {
            padding: 5px;
            text-transform: uppercase;
            font-size: get-font-size(small);
            font-weight: get-font-weight(semi-bold);
            color: theme(primary);
          }

          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
          }
        }

        &-name {
          margin-bottom: 1.875rem;
          width: 90%;

          &__label {
            margin-bottom: 5px;
            text-transform: capitalize;
            font-size: get-font-size(small);
            color: theme(primary);
          }

          &__field {
            @include respond('xl-') {
              width: 100%;
            }

            border-bottom: 1px solid color(grey);
            padding-bottom: 5px;
            width: 60%;

            .text-input {
              border: transparent !important;
              width: 100% !important;
              background: color(white);
              text-transform: capitalize;
              font-size: get-font-size(medium);
              color: color(dark) !important;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  &__report {
    flex-direction: column;
    margin-bottom: 1.563rem;
    box-shadow: 0 0 2px rgba(67, 102, 118, 0.501217);
    border-radius: 3px;
    border: 0;
    padding: 1.875rem 1.250rem;
    position: relative;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.875rem;
      width: 100%;

      h3 {
        margin-right: auto;
      }

      &__filter-and-download {
        display: flex;
      }

      &__download,
      &__filter {
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #6a69e49c;
        padding: 0 0.313rem;
        height: 1.875rem;
        cursor: pointer;

        &-text {
          margin-top: 8px;
          margin-right: 5px;
          letter-spacing: 1px;
          font-size: 0.750rem;
          color: #6a69e4;
        }
      }

      &__filter {
        position: relative;
        margin: 0px 10px;
        border: 1px solid rgba(67, 102, 118, 0.459);

        &-text {
          color: rgba(67, 102, 118, 0.459);
        }

        &-child-linker {
          position: absolute;
          width: 50px;
          transform: rotate(45deg);
          height: 47px;
          background: white;
          bottom: -73px;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
          z-index: 3;
        }
      }
    }

    .body {
      width: 100%;
    }

    .form-header {
      margin-bottom: 1.875rem;
      width: 100%;

      &__title {
        font-size: 1.250rem;
        font-weight: 600;
      }

      &__subtitle {
        margin-bottom: 0;
        font-size: 1.000rem;
        color: color(grey);
      }
    }

    &--data {
      min-height: 290px;
    }
  }

  &__landing-screen {
    // @include respond('xl-') {
    //   padding: 1.875rem 0.625rem;
    // }
    // flex-direction: column;
    justify-content: center;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0;
    padding: 0;
    height: 140px;
    overflow: hidden;
    background-color: color(white);
    transition: transform 0.3s;

    // &:hover {
    //   transform: scale(1.02);
    // }
    img {
      object-fit: cover;
      width: 100%;
    }

    .overlay-effect {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      transition: 0.5s ease;
      background-color: rgba(0, 0, 0, 0.64);
      overflow: hidden;
    }

    .avatar {
      width: 100%;
      height: 100%;
      // @include round-image(9.375rem, cover);
      // margin: 0 auto 30px;
    }

    .details {
      text-align: center;
      position: absolute;
      top: 25%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      z-index: 100;
      padding: 0 20px;
    }

    .title {
      margin-bottom: 20px;
      // text-transform: uppercase;
      // line-height: 1.4;
      font-size: 18px;
      color: color(white);
    }

    .subtitle {
      // text-align: center;
      // text-transform: capitalize;
      line-height: 1.4;
      font-size: 14px;
      color: color(white);
    }
  }

  &__settings {
    @include respond('sm') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    @include respond('xs') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    flex-direction: column;
    align-items: normal;
    border-radius: 4px;
    border: 1px solid color(light-grey);
    padding: 1.250rem;
    height: 100%;

    &-body {
      margin-top: 0.625rem;
    }

    &-block {
      @include respond('xs') {
        margin: 1.250rem 0;
      }

      margin: 1.250rem;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      color: color(dark);
    }

    &-text {
      margin-bottom: 0;
      font-size: 0.875rem;
      color: color(grey);
    }

    &-title {
      overflow: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__hmo {
    @include respond('sm') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    @include respond('xs') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.438rem;
    box-shadow: 0 2px 7px rgba(120, 137, 149, 0.254784);
    border-radius: 3px;
    border: 0;
    padding: 1.250rem;
    min-height: 310px;

    &-header {
      margin-bottom: 0.469rem;
      width: 100%;

      &__logo {
        margin-top: -5px;
        max-width: 100%;
        max-height: 60px;
      }
    }

    &-body {
      max-width: 100%;

      .email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .iconmoon {
        font-size: 12px;
      }
    }

    &-title {
      // @include text-wrap();
      max-width: 100%;
      text-transform: capitalize;
      line-height: 23px;
      font-size: 18px;
    }
  }

  &__ward {
    @include respond('sm') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    @include respond('xs') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.438rem;
    box-shadow: 0 2px 7px rgba(120, 137, 149, 0.254784);
    border-radius: 3px;
    border: 0;
    padding: 0;
    min-height: 350px;

    &-header {
      padding: 1.250rem;
      width: 100%;
    }

    &-body {
      @include flex-display(column);
      width: 100%;
      text-decoration: none !important;

      .initials-label {
        @include flex-display(column);
        margin-bottom: 35px;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        background-color: color(off-purple);
        text-transform: uppercase;
        line-height: 30px;
        font-size: 23px;
        color: theme(primary);
      }

      .title {
        line-height: 23px;
        font-size: 18px;
      }

      .stats {
        border-radius: 5px;
        padding: 0.469rem;
        min-width: 175px;
        max-width: 50%;
        background-color: rgba(250, 70, 89, 0.15);
        text-align: center;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid color(light-grey);
      padding: 1.250rem;
      width: 100%;
    }
  }

  &__bank {
    @include respond('sm') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    @include respond('xs') {
      margin-bottom: 1.250rem;
      height: auto;
    }

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2.438rem;
    box-shadow: 0 2px 7px rgba(120, 137, 149, 0.254784);
    border-radius: 3px;
    border: 0;
    padding: 1.250rem;
    min-height: 80px;

    &-body {
      .iconmoon {
        font-size: 12px;
      }
    }

    &-title {
      overflow: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      line-height: 23px;
      white-space: nowrap;
      font-size: 18px;
    }
  }

  &__task {
    height: 4.688rem;

    &:first-child {
      margin-top: 0.313rem;
    }

    &:not(:last-child) {
      margin-bottom: 0.938rem;
    }

    &__body {
      flex: 1;
    }

    &__notify-deadline {
      position: absolute;
      top: -5px;
      right: -2px;
      border-radius: 50%;
      width: 0.750rem;
      height: 0.750rem;
      animation: deadline 1500ms infinite ease-in-out forwards;

      &.due {
        background: rgba(#ffbf00, 0.9);
      }

      &.overdue {
        background: rgba(crimson, 0.9);
      }
    }

    &__location {
      text-transform: uppercase;
      font-size: 0.750rem;
    }

    &__patient {
      text-transform: capitalize;
      font-size: 0.781rem;
      color: grey;
    }

    &__date {
      margin-bottom: 0.625rem;
      font-size: 0.750rem;
      color: color(grey);
    }

    &__service {
      margin-bottom: 0;
      text-transform: capitalize;
      font-size: 0.875rem;
      color: color(dark);
    }

    &__action {
      display: flex;
    }

    &__button {
      border-radius: 2px;
      border: 0;
      min-width: 3.750rem;
      height: 1.875rem;
      font-size: 0.750rem;
      transition: box-shadow 0.3s, transform 0.3s;

      &:hover {
        box-shadow: 0 0 2px rgba(color(black), 0.2);
        transform: translateY(-3px);
      }

      &:active {
        box-shadow: none !important;
        background: inherit !important;
        color: inherit !important;
        transform: translateY(0);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__user {
    margin-bottom: 0.938rem;

    &--label {
      display: flex;
      opacity: 1;
      transition: opacity 1.9s;

      .input {
        input {
          display: none;
        }

        .checker {
          position: relative;
          display: block;
          border-radius: 50%;
          border: 1px solid rgba(68, 157, 249, 0.3);
          width: 1.250rem;
          height: 1.250rem;
          overflow: hidden;

          .is-checked {
            @include flex-display(row);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: color(white);
            opacity: 1;
            font-family: 'Font Awesome 5 Free';
            font-size: 0.750rem;
            color: rgba(68, 157, 249, 0.6);
            transition: opacity 0.3s;
            content: '\f00c';
          }
        }
      }
    }

    &__icon {
      margin-right: 0.938rem;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: color(grey);
      opacity: 0.6;
    }

    &__avatar {
      @include round-image(2.188rem, cover);
      margin-right: 0.625rem;
      margin-bottom: 0;
      box-shadow: 0 0 1px 0 rgba(color(black), 0.22);
    }

    &__details {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 5px;
    }

    &__title {
      margin: 0;
      overflow: hidden;
      text-transform: initial;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      font-size: 0.875rem;
      font-weight: 500;
      color: color(dark);
      user-select: none;
    }

    &__subtitle {
      margin: 0;
      overflow: hidden;
      opacity: 0.7;
      text-transform: initial;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.750rem;
      font-weight: 400;
      color: color(grey);
      user-select: none;
    }
  }

  &__table-listing {
    display: block;
    border: 0;
    padding: 0;
    background-color: transparent;

    .no-content {
      @include flex-display(column);
      width: 100%;
      min-height: 3.125rem;
    }

    .content {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.563rem;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      border: 1px solid #eaf0f7;
      padding: 1.250rem 0.938rem 0.625rem;
      min-height: 5.000rem;
      background: color(white);

      &__deactivated {
        opacity: 0.6;
      }

      &__items {
        flex: 1;

        .item {
          margin-bottom: 0.938rem;
          color: rgba(74, 74, 74, 0.72);

          &__title {
            @include font-size(small);
            line-height: 18px;
            font-weight: 500;
            color: color(dark);
          }
        }

        .badge {
          margin-bottom: 0.938rem;
          background: #f8f8ff;
          color: theme(primary);
        }
      }

      &__action-icons {
        margin-left: 5px;
        // max-width: 2.188rem;

        .table__icon {
          color: theme(primary);
        }
      }
    }
  }
}

.delete-assignee-icon {
  position: absolute;
  top: -10px;
  right: -15px;

  svg {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: rgba(color(white), 0.9);
    cursor: pointer;
    fill: rgba(theme(secondary), 0.5);
  }
}

.delete-assignee {
  animation: deleteAssignee 0.6s ease-in both;
}

@keyframes deleteAssignee {
  0% {
    opacity: 0.9;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(0.1);
  }
}
