.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;

  span {
    position: relative;
    z-index: 2;
    text-align: center;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .tooltip {
    position: absolute;
    top: 0;
    background: #fff;
    padding: 8px 8px;
    border-radius: 25px;
    width: 100px;
    color: white;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &:before {
      position: absolute;
      content: '';
      height: 15px;
      width: 15px;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      pointer-events: auto;
      top: -40px;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
    }
    span {
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
    }
  }
}
.delete {
  &:hover {
    .tooltip {
      background: #3e3c90;
      &:before {
        background: #3e3c90;
      }
    }
  }
}
