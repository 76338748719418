.popups {
  &__bubble {
    display: inline-block;
    position: relative;

    &-header {
      border-top: 3px solid theme(primary);
      background-color: theme(primary);
    }

    &-cursor {
      cursor: pointer;
    }

    &-count {
      color: theme(primary);
    }

    small {
      cursor: pointer;
      padding-right: 5px;
    }

    &-options {
      display: none;
      position: absolute;
      top: 35px;
      left: -13px;
      width: 155px;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
    }

    &-options::before {
      position: absolute;
      z-index: -1;
      content: '';
      left: calc(10% - 3px);
      top: -8px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent theme(primary) transparent;
      transition-duration: 0.3s;
      transition-property: transform;
    }

    &__height {
      min-height: 100px;
      overflow: auto;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 5px;
    }

    li {
      @include font-size(x-small);
      padding: 8px 12px;
      border-bottom: 1px solid color(gray);

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }

    i {
      font-size: 20px;
    }

    .show {
      display: block;
    }
  }
}
