@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 210 20% 98%;
    --foreground: 218 24% 27%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 245 90% 65%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }
}


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/animations';

// 2. Vendors
@import '~hamburgers/dist/hamburgers.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-datepicker/dist/react-datepicker.css';

@import 'vendors/icomoon';
@import 'vendors/rc-time-picker';
@import 'vendors/day-picker';

// 3. Base stuff
@import 'base/base';
@import 'base/fonts';
@import 'base/typography';
@import 'base/helpers';

// 4. Layout-related sections
@import 'layout/header';
@import 'layout/plain';
@import 'layout/layout-main';
@import 'layout/footer';
@import 'layout/scrollbar';

// 5. Components
@import 'components/index';

// 6. Page-specific styles
@import 'pages/auth';
@import 'pages/dashboard';
@import 'pages/errorPage';
@import 'pages/settings/index';
@import 'pages/settings/staff';
@import 'pages/supplier';
@import 'pages/user-profile';
@import 'pages/procedure-result-form';
@import 'pages/procedure-item';
@import 'pages/support';
@import 'pages/aggrements';
