.support-modal {
  position: absolute;
  z-index: 2000;
  bottom: 10%;
  right: 10%;


  &-content {
    box-sizing: border-box;
    box-shadow: 0 5px 15px rgba(21, 21, 25, 0.08);
    width: 280px;
    color: #fff;
    padding: 0.938rem;
    border-radius: 8px;
  }
}
