.staff {
  .table {
    &-body {
      td:nth-child(1),
      td:nth-child(3),
      td:nth-child(4) {
        text-transform: capitalize;
      }
    }
  }

  @include respond('xl-') {
    .table {
      // th:nth-child(3),
      // td:nth-child(3) {
      //   display: none;
      // }
    }
  }

  @include respond('am') {
    .table {
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
      }
    }
  }

  @include respond('sm') {
    .table {
      th:nth-child(4),
      td:nth-child(4) {
        display: none;
      }
    }
  }

  @include respond('xs') {
    .table {
      th:nth-child(4),
      td:nth-child(4) {
        display: none;
      }
    }
  }
}
