.medical-standard {
  position: relative;

  &__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
    padding: 20px 0;
  }

  &__option {
    @include respond('as') {
      margin: 15px auto;
      width: 80%;
    }
    margin: 20px 0;
    width: 30%;

    a {
      text-decoration: none;
    }
  }

  .table {
    &-body {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        text-transform: capitalize;
      }
    }
  }
}
