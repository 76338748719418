.view {
  &-formatter {
    margin-bottom: 25px;

    p {
      @include font-weight(medium);
      margin-bottom: 15px;
    }

    p:first-child {
      @include font-weight(regular);
      @include font-size(small);
      margin-bottom: 8px;
      color: color(grey);
    }

    p.not-assigned {
      @include font-weight(bold);
      color: color(dark);
    }
  }

  &-link {
    width: max-content;
    cursor: pointer;
    text-decoration: underline;

    &.red {
      color: color(watermelon);
    }

    &.blue {
      color: color(purple);
    }
  }
}
