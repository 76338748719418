.tab-switcher {
  @include flex-display(row);
  border-radius: 5px;
  border: 1px solid theme(primary);

  .tab-switcher__text {
    flex: 1;
    margin-bottom: 0;
    padding: 0.313rem 0;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    color: theme(primary);

    &:hover {
      color: theme(primary);
    }

    &.active {
      border-radius: 2px;
      background-color: theme(primary);
      color: color(white);
    }
  }
}
