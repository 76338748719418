.error-page {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1.250rem;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  &__content {
    @include flex-display(column);
    height: 100%;
    min-height: 600px;

    @include respond('r2-') {
      margin-top: 1.250rem;
      min-height: 100%;
    }
  }

  &__description {
    @include font-size(small);
    margin-bottom: 1.250rem;
    letter-spacing: 0.050em;
  }

  &__heading {
    @include font-size(large);
    @include font-weight(bold);
    margin-bottom: 1.250rem;
    letter-spacing: 0.013em;
  }

  &__image {
    margin: 0 auto;
    margin-bottom: 1.250rem;
    width: 100%;
    max-width: 600px;
  }

  &__message {
    width: 100%;
    max-width: 400px;
    text-align: center;
    color: color(dark);
  }
}
