.transfer-patient {
  margin-top: -0.625rem;

  &__search {
    @include flex-display(row);

    .dashboard-search-bar {
        flex: 1;
        margin-right: 0.625rem;
        border: 0;
    }
  }

  &__consultant,
  &__vital-notice {
    border-radius: 5px;
    padding: 0.313rem 0.938rem;
    max-width: max-content;
  }

  &__vital-notice {
    margin-bottom: 3.125rem;
    background-color: theme(primaryShade);
    color: theme(primary);
  }

  &__consultant {
    margin-bottom: 0.938rem;
    background-color: theme(secondaryShade);
    color: theme(secondary);
  }

  &__tab-option {
    margin-bottom: 0.938rem;

    .header {
      @include flex-display(row);
      justify-content: flex-start;

      color: color(grey);

      i {
        padding-top: 5px;
        font-size: 1.250rem;
      }
    }

    .body {
      padding-left: 1.563rem;
    }
  }
}
