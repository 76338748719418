.feedback {

  &-success {

    &__logo {
      margin-bottom: 35px;
    }

  }
}
