.administer-medication {

    &-modal {

        .modal__header {
            border-bottom: 0;
        }

        .modal__body {
            padding-top: 20px;
        }
        
    }

    textArea {
        min-height: 168px;
    }

}
