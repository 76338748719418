@mixin font-size($keyword) {
  font-size: get-font-size($keyword);
}

@mixin font-weight($keyword) {
  font-weight: get-font-weight($keyword);
}

@mixin respond($breakpoint) {
  @if ($breakpoint == 'xs') {
    @media (max-width: 575px) {
      @content;
    }
  }
  @if ($breakpoint == 'sm') {
    @media (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }
  @if ($breakpoint == 'r2-') {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if ($breakpoint == 'as') {
    @media (max-width: 767px) {
      @content;
    }
  }
  @if ($breakpoint == 'ab') {
    @media (min-width: 769px) {
      @content;
    }
  }
  @if ($breakpoint == 'am') {
    @media (max-width: 991px) {
      @content;
    }
  }
  @if ($breakpoint == 'fm') {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if ($breakpoint == 'md-') {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if ($breakpoint == 'md') {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @if ($breakpoint == 'md-or') {
    @media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
      @content;
    }
  }

  @if ($breakpoint == 'lg') {
    @media (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }
  @if ($breakpoint == 'md-lg') {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  }
  @if ($breakpoint == 'xl') {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @if ($breakpoint == 'xxl') {
    @media (min-width: 1550px) {
      @content;
    }
  }
  @if ($breakpoint == 'xxl-') {
    @media (max-width: 1550px) {
      @content;
    }
  }
  @if ($breakpoint == 'xl-') {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if ($breakpoint == 'rl-') {
    @media (max-width: 1110px) {
      @content;
    }
  }
}

@mixin triangle($dir, $size, $color) {
  border: $size transparent solid;
  @if ($dir == 'top' or $dir == 'up') {
    border-bottom-color: $color;
  } @else if ($dir == 'right' or $dir == 'after') {
    border-left-color: $color;
  } @else if ($dir == 'bottom' or $dir == 'down') {
    border-top-color: $color;
  } @else if ($dir == 'left' or $dir == 'before') {
    border-right-color: $color;
  }
}

@mixin abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin flex-display($flex-direction) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: center;
  justify-content: center;
}

@mixin round-image($size, $fit) {
  border-radius: 50%;
  width: $size;
  height: $size;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: $fit;
  }
}

@mixin text-wrap() {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin padding-responsive($xs, $sm, $md) {
  @include respond('sm') {
    padding: $sm;
  }

  @include respond('xs') {
    padding: $xs;
  }

  padding: $md;
}

@mixin font-responsive($xs, $sm, $md) {
  @include respond('sm') {
    font-size: $sm;
  }

  @include respond('xs') {
    font-size: $xs;
  }

  font-size: $md;
}

// Border radius
@mixin border-radius($radius) {
  border-radius: $radius;
  background-clip: padding-box;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

// Inherit style for link
@mixin inherit-link() {
  a {
    text-decoration: none;
    color: inherit !important;
  }
}

@mixin nav-border-bottom($color, $important: false) {
  @if $important {
    border-bottom: 3px solid $color !important;
  } @else {
    border-bottom: 3px solid $color;
  }
}

@mixin filter_tablet_responsive {
  @media (min-width: 768px) and (max-width: 932px) {
    right: -170px;
    top: 10px;
  }
}
@mixin reportcard_tablet_responsive {
  @media (min-width: 992px) {
    right: -470px;
    top: 10px;
  }
}
@mixin reportcard_arrow_tablet_responsive {
  @media (min-width: 768px) and (max-width: 991px) {
    right: 20px;
  }
  @media (min-width: 991px) {
    right: 470px;
  }
}

@mixin filter_arrow_tablet_responsive {
  @media (min-width: 768px) and (max-width: 932px) {
    right: 150px;
  }
}

@mixin appointment_filter_tablet_responsive {
  @media (min-width: 768px) and (max-width: 991px) {
    right: -280px;
  }
}

@mixin appointment_arrow_filter_tablet_responsive {
  @media (min-width: 768px) and (max-width: 991px) {
    right: 288px;
  }
}

@mixin filter_container_tablet_responsive {
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}
