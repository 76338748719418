.pagination {
  margin-bottom: 0;

  &--block {
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    padding: 2.500rem;
    width: 100%;
  }

  &__caption {
    @include font-size(small);
    @include font-weight(semi-bold);
    text-transform: uppercase;
    color: color(grey);
  }

  .page-link {
    @include font-size(x-small);
    @include font-weight(semi-bold);
    box-shadow: none;
    padding: 0.469rem 0.625rem;
    color: color(grey);

    &.active {
      background-color: theme(primary);
      color: color(white);
    }
  }

  &__inner {
    .page-item {
      &.active {
        .page-link {
          background-color: theme(primary);
          border-color: theme(primary);
        }
      }
    }
  }
}

.page-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.875rem 0;

  @include respond('as') {
    z-index: 33;
    position: fixed;
    top: calc(100vh - 100px);
    left: 0;
    box-shadow: 0px -11px 15px rgba(67, 102, 118, 0.0484);
    padding: 0.625rem;
    width: 100%;
    height: 70px;
    background-color: color(white);
  }

  &__cta {
    margin-left: 20px;
  }
}
