.config-form {
  position: relative;
  background: color(white);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid color(light-grey);
    padding: 1.250rem 1.563rem;

    &-title {
      @include font-size(large);
      @include font-weight(regular);
      text-transform: capitalize;
    }

    .settings-icon {
      cursor: pointer;
      transition: 0.5s transform;
    }
  }

  &__main {
    padding: 1.875rem 0.625rem;
  }
}
