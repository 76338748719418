.tag {
  border-radius: 20px;
  display: inline-flex;
  padding: 5px 10px;
  font-size: 12px;

  &--red {
    color: #fa4659;
    background: rgba(250, 70, 89, 0.15);
  }

  &--purple {
    // color: black;
    background: rgba(106, 105, 228, 0.3);
  }

  &--yellow {
    background: rgba(245, 166, 35, 0.3);
  }

  &--primary {
    border-radius: 7px;
    padding: 2px 5px;
  }
}
