.profile-detail {
    border-bottom: 1px solid color(light-grey);
    padding: 0.313rem 0 0.625rem;

    &__label {
        margin-bottom: 0.313rem;
        letter-spacing: 0.5px;
        font-size: 0.750rem;
        color: color(grey);
    }

    &__info {
        margin-bottom: 0;
        padding-right: 1.250rem;
        font-size: 0.875rem;
        color: color(dark);
    }
}
