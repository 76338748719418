.table {
  @include respond('as') {
    overflow: auto;
  }
  margin-bottom: 0;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;

  &__text {
    &-number {
      margin-left: 0.625rem;
      color: theme(primary);
    }
  }

  &__badge {
    display: inline-block;
    border-radius: 10px;
    padding: 0.313rem 0.625rem;
    background: #f8f8ff;
    text-align: center;
    text-transform: capitalize;
    color: theme(primary);

    &-number {
      margin-left: 0.625rem;
      color: theme(primary);
    }
  }

  &-body {
    tr:nth-child(1) {
      td {
        padding-top: 2.500rem !important;
      }
      td.table-cell-sm {
        padding-top: 1.875rem !important;
      }
    }

    tr:last-child {
      td {
        .context-menu {
          margin: auto;
          max-width: 20px;
        }

        .context-menu-body {
          position: relative;
          left: -90px;
          width: max-content;
        }

        .items-hover-parent:hover {
          position: relative;

          .items-hover {
            position: relative;
            margin-bottom: 120%;
          }

          .items-hover-content {
            top: 4px;
          }
        }
      }
    }

    .deactivated {
      cursor: pointer;
      opacity: 0.4;
    }

    .mobile-row .table-cell {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }

    .mobile-row > td:first-child {
      box-shadow: 8px 1px 6px 0 #00000014;
      padding-right: 80px !important;
    }

    // .mobile-row > td:not(:first-child) {
    //   padding: 0 !important;
    //   // padding-top: 6% !important;
    //   text-align: center;
    // }
  }

  &-cell {
    margin: 0.625rem 0;
    border: 0 !important;
    border-bottom: 1px solid color(light-grey) !important;
    font-size: 0.875rem;
    color: rgba(74, 74, 74, 0.72);

    &.borderless {
      border-bottom: none !important;
    }

    .clickable {
      cursor: pointer;
    }

    .toggle {
      margin-left: 0;
    }

    &.no-padding {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    &.right-padding {
      padding-right: 0.313rem !important;
    }

    &.left-padding {
      padding-left: 0.625rem !important;
    }

    &.opposite-padding {
      padding-right: 0.625rem !important;
      padding-left: 0.625rem !important;
    }
  }

  &-header {
    background: rgba(106, 105, 228, 0.04);
    text-transform: capitalize;
  }

  &-head,
  &-cell {
    @include respond('xs') {
      padding: 1.250rem !important;
    }
    padding: 1.250rem 2.500rem !important;

    // &-more {
    //   color: color(purple)
    // }
  }

  &-cell-sm {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  &-head {
    border: 0 !important;
    padding-top: 0.938rem !important;
    padding-bottom: 0.938rem !important;
    vertical-align: middle !important;
    font-size: 0.875rem;
    color: theme(primary);
  }

  &__icon {
    margin-left: 0.938rem;
    cursor: pointer;
  }

  &__button {
    max-width: 5.000rem;
    cursor: pointer;
    text-align: center;

    &--text {
      margin: auto;
      border-bottom: 1px solid color(primary);
      padding-bottom: 0.313rem;
      max-width: 90%;
      color: color(primary);
    }
  }

  &-ellipsis-menu {
    right: 22px;
    padding: 21px 20px;
    width: 100%;
  }

  tr.expandable {
    cursor: pointer;
  }

  tr i.expandable {
    cursor: pointer;
  }

  tr.expanded {
    background-color: rgba(106, 105, 228, 0.07);

    &-row {
      &:hover {
        background-color: transparent;
      }
    }
  }

  td.expanded {
    border-left: 4px solid #3399cc;
    &-nopadding {
      padding: 0;
    }
  }
}

.table-actions {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &__searchbar {
    @include respond('as') {
      margin: 10px 0;
      width: 60%;
    }
    width: 30%;
  }

  &__cta {
    @include respond('as') {
      width: 40%;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 70%;
  }

  &__mobile-cta {
    @include respond('as') {
      z-index: 33;
      position: fixed;
      top: calc(100vh - 10.938rem);
      right: 1.563rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 50%;
      border: 0;
      width: 60px;
      height: 60px;
      background: theme(primary);

      &:active,
      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        font-size: 38px;
        color: #fff;
      }
    }

    display: none;
  }

  &__add-action,
  &__import-action {
    @include respond('as') {
      display: none;
    }

    margin-left: 15px;
  }
}
