.billing_stats_card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 310px;
  border-radius: 10px;
  gap: 20px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(106, 105, 228, 0.03);
}
