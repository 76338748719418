.access-log-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.access-log-card {
  background: #fff;
  width: 772px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.17);
  border-radius: 7px;

  .access-log-header {
    padding: 30px 30px 25px;
    border: 1px solid rgba(67, 102, 118, 0.148353);

    &__title {
      font-size: 20px;
    }
  }

  .access-log-body {
    padding: 24px 30px 40px;
    height: 465px;
    overflow: auto;
  }
}

.access-log {
  &__badge {
    display: inline-block;
    margin-right: 0.625rem;
    border-radius: 52px;
    padding: 5px 15px;

    &--red {
      color: color(watermelon);
      background-color: rgba($color: color(watermelon), $alpha: 0.1);
    }

    &--green {
      color: color(green);
      background-color: rgba($color: color(green), $alpha: 0.1);
    }

    &--green-dark {
      color: #436676;
      background: rgba(67, 102, 118, 0.3);
    }

    &--purple {
      color: color(purple);
      background-color: rgba($color: color(purple), $alpha: 0.1);
    }

    &--grey {
      color: color(white);
      background: rgba(66, 75, 95, 1);
    }

    &--purple-dark {
      color: #6a69e4;
      background: rgba(106, 105, 228, 0.15);
    }
  }

  &--text {
    line-height: 20px;
  }
}

.archive-records {
  &-search {
    padding: 28px 25px;
  }

  &-list {
    &_total {
      border-top: 1px solid rgba(67, 102, 118, 0.148353);
      border-bottom: 1px solid rgba(67, 102, 118, 0.148353);
    }

    tr {
      &:first-of-type {
        td {
          padding: 30px 25px;
        }
      }

      &:nth-of-type(2) {
        border-top: none;
      }

      td {
        padding: 16px 25px;
      }

      td:nth-of-type(5) {
        width: 10%;
      }

      td:last-of-type {
        width: 5%;
      }
    }
  }

  &-borderline {
    border-bottom: 0.2px solid #9b9b9b;
    border-top: 0.2px solid #9b9b9b;
    color: rgba(74, 74, 74, 0.72);
  }
}
