.patients-admission-card {

    &-status {
        @include font-size(small);
        border-radius: 20px;
        border: 1px solid color(primary);
        padding: 8px 14px;
        min-width: 136px;
        color: color(primary);
    }

    &-context-menu-body {
        padding: 0 20px;
        min-width: 160px;

        li {
            font-size: unset !important;
        }
    }
}
