.appointment-state {
  padding: 7px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.13);

  &.indigo {
    border: 1px solid #5655d0;
    color: #5655d0;
  }

  &.yellow {
    border: 1px solid #f5a623;
    color: #f5a623;
  }
}
