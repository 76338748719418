.dispense-modal {
  &-header {
    border-radius: 4px;
    padding: 16px 15px;
    background: firebrick;

    &__detail {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tile {
        flex: 1;
      }
    }

    .title {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .table-head {
    background: rgba(106, 105, 228, 0.07);
  }
}
