.discount {
  &.content-table {
    padding-top: 40px;
  }

  &-page-select {
    display: flex;

    .form-group {
      @include respond(as) {
        margin-right: 10px;
        min-width: 50%;
        max-width: 50%;
      }
      margin-bottom: 0;
      box-shadow: 2px 4px 10px 0 rgba(21, 21, 25, 0.08);
      border: 0;
      min-width: 288px;
      max-width: 340px;
    }

    .supplier-blacklist-button {
      line-height: -2;
    }
  }

  &-create {
    .radio-row p {
      @include font-size(small);
      color: color(grey);
    }

    .radio-row label {
      @include font-size(small);
    }

    input::placeholder {
      @include font-size(small);
      color: rgba(74, 74, 74, 0.4);
    }

    .check-field {
      &.form-group {
        outline: none;
        border: 0 solid white;

        &:focus-within {
          box-shadow: none;
        }
      }

      label {
        margin-bottom: -17px;
        margin-left: 20px;
        border: 0 !important;
      }
    }
  }

  &-archive-button {
    margin: auto 0 0 auto;
    width: max-content;
    height: max-content;

    &:hover {
      text-underline-position: under;
    }
  }
}
