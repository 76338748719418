.items-hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &-parent {
      position: relative;
      width: max-content;

      &:hover .items-hover-content {
        display: unset;
      }
    }

    &-content::before,
    &-content::after {
      position: absolute;
      top: -15px;
      right: 10px;
      border-right: 15px solid transparent;
      border-bottom: 15px solid color(light-grey);
      border-left: 15px solid transparent;
      width: 0;
      height: 0;
      transform: rotate(0deg);
      content: '';
    }

    &-content::after {
      border-bottom: 18px solid color(white);
    }

    &-content {
      @include font-size(x-small);
      z-index: 2;
      position: absolute;
      top: 30px;
      right: -23px;
      display: none;
      box-shadow: 0 4px 4px rgba(21, 21, 25, 0.08);
      border-radius: 5px;
      border: 2px solid color(light-grey);
      border-top: 3px solid red;
      padding: 0px;
      min-width: 200px;
      max-width: 220px;
      background: color(white);
      animation: filter-option 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      p {
        margin: 15px 0;
        border-bottom: 1px solid color(dark);
        padding-bottom: 5px;
        padding-left: 20px;

        &:last-child {
          border-bottom: 0;
        }

      }

    }
  }
  