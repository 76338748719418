.medication-log {

    &-content {
        overflow: scroll;
    }

    &-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px 15% 15px 25px;


        &-details {
            display: flex;

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 15px;
                margin-right: 20px;
                border-radius: 50px;
                width: 31px;
                height: 31px;
                background: lighten(color(purple), 23);
            }

            &-reference {
                p {
                    @include font-size(small);
                }

                p:first-child {
                    @include font-size(regular);
                }

                p:nth-child(2) {
                    color: color(grey);
                }
            }

        }

        &-dispense {
            @include font-size(small);
            color: color(primary);
        }

    }

}
