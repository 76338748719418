.patients-dashboard {

  &-row {
    @include respond(as) {
      flex-direction: column;
    }

    &-left {
      @include respond(as) {
        max-width: 100% !important;
      }
      flex: unset !important;
      max-width: calc(100% - 400px) !important;
    }

    &-right {
      @include respond(as) {
        max-width: 100% !important;
      }
      flex: unset !important;
      max-width: 400px !important;
    }


  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .nocontent {
    margin-top: 0;
  }

  .loader-block {
    min-height: inherit;
  }

  &-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    height: auto;
    background: #fff;
  }

  &-aside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    height: 47.3%;
    min-height: 430px;
    max-height: 51%;
    background: #fff;
  }

  &-log {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    background: #fff;
    height: auto;
  }

  &-head {
    padding: 20px;
    border-bottom: 1px solid rgba(67, 102, 118, 0.148353);
    font-size: 20px;
  }

  &-body {
    padding: 20px;
    height: 639px;
    overflow: auto;

    &__button {
      border: 1px solid #6A69E4;
      border-radius: 4px;
      padding: 6px 10px;

    }
  }

  &-footer {
    padding: 20px 0 15px;
    border-bottom: 1px solid rgba(67, 102, 118, 0.148353);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }


  &__appointment-group {
    padding: 0 1.250rem;
    width: 100%;
    height: 73.7%;

    .card__frontdesk-appointment {
      @include respond('xxl-') {
        &:nth-child(2n-1) {
          margin-right: 1%;
          margin-left: 2.5%;
        }
      }

      @include respond('xxl') {
        width: 31.55%;

        &:nth-child(3n-1) {
          margin-right: 1.25%;
          margin-left: 1.25%;
        }

        &:nth-child(3n-2) {
          margin-left: 15px;
          // background: red;
        }
      }

      @include respond('am') {
        margin-right: 0;
        margin-left: 3% !important;
        width: 94%;
      }

      margin-bottom: 20px;
      width: 47%;
    }
  }

}

.admission-assessment {
  margin: 0 0.750rem;

  &__vitals {
    @include flex-display(row);
    border-radius: 4px;
    border: 1px solid #9B9B9B;

    .title {
      border-right: 1px solid #9B9B9B;
      padding: 1.125rem 0.625rem;
      width: 4.375rem;
      text-align: center;
    }

    .details {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-self: stretch;

      &-group {
        display: flex;
        align-items: center;
        padding-left: 1.250rem;
        width: 33.33%;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          border-right: 1px solid #9b9b9b;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border-bottom: 1px solid #9b9b9b;
        }
      }
    }
  }

  &__card {
    box-shadow: 0 2px 10px rgba(67, 102, 118, 0.148353);
    border-radius: 10px 10px 7px 7px;
    height: 100%;
    max-height: 3.250rem;
    overflow: hidden;
    background: color(white);

    &.is-open {
      max-height: 75.000rem;
    }

    &-header {
      @include flex-display(row);
      justify-content: space-between;
      padding: 0.938rem 1.250rem;
      background: rgba(106, 105, 228, 0.103119);

      i {
        font-size: 1.375rem;
      }
    }

    &-content {
      padding: 1.563rem 1.125rem;
    }
  }
}

.admission-past-records {
  &__records {
    @include flex-display(row);

    &-files {
      @include flex-display(row);
      flex: 1;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-right: 5px;
    }

    &-file {
      margin: 0.625rem;
      margin-left: 0;
      border-radius: 7px;
      border: 1px solid rgba(67, 102, 118, 0.148353);
      width: 128px;
      height: 79px;
      overflow: hidden;
      background: #E2E2F9;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-count {
      @include round-image(34px, contain);
      @include flex-display(row);
      box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
      background: #E2E2F9;
    }
  }
}

.session-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  &-skip {
    cursor: pointer;
  }
}
