.analytics {
  &-card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    padding: 20px 15px;
    background: #fff;
  }

  &-card-body {
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    border-radius: 7px;
    padding: 21px 15px;
    min-height: 200px;
    background: #fff;

    &.raised {
      background: #ffffff;
      border: 1px solid #eaf0f7;
      box-shadow: 0 2px 90px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }

    &--auto {
      height: auto;
    }

    &__control {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;

  &--indigo {
    background: #6a69e4;
  }

  &--deep-green {
    background: #2ed47a;
  }

  &--green {
    background: #58c9b9;
  }

  &--orange {
    background: #b89249;
  }

  &--red {
    background: #f55f44;
  }

  &--peach {
    background: #ff7473;
  }

  &--deep-peach {
    background: #ff7473;
  }

  &--golden-brown {
    background: #b89249;
  }
}
