.frontdesk-body .nav {
  &-item {
    border: 2px solid transparent;

    .active {
      @include nav-border-bottom(var(--primary-color), true);
      border-color: transparent;
      background: transparent;
      color: var(--primary-color);
    }
  }

  &-link {
    width: max-content;
    cursor: pointer;
    text-decoration: none;

    &.blue {
      color: color(purple);
    }
  }

  &-tabs {
    border-bottom: 0;
  }
}
