.notification {
    &__content {
        margin: 3.125rem 0;
        max-height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    &__mark-action {
        padding: 0.313rem 0.625rem;
        margin-bottom: 1.875rem;
        border-radius: 15px;
        background-color: color(red);
        color: color(white);
        cursor: pointer;
    }
    &__card {
        position: relative;
        background: color(light-blue);
        padding: 0.625rem 1.250rem;
        border-radius: 5px;
        cursor: pointer;
        &-indicator {
            position: absolute;
            top: 5%;
            right: 2%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: color(red);
        }
        &-time {
            text-align: right;
            color: color(very-light-grey);
            margin: 0;
        }
    }
}
