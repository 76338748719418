.vitals {
  &__badge {
    display: inline-block;
    margin-right: 0.625rem;
    border-radius: 10px;
    padding: 0 5px;
    word-break: break-word;

    &--red {
      color: color(watermelon);
      background-color: rgba($color: color(watermelon), $alpha: 0.1);
    }

    &--yellow {
      color: color(yellow);
      background-color: rgba($color: color(yellow), $alpha: 0.1);
    }

    &--brown {
      color: brown;
      background-color: rgba(165, 42, 42, 0.1);
    }

    &--green {
      color: color(green);
      background-color: rgba($color: color(green), $alpha: 0.1);
    }

    &--purple {
      color: color(purple);
      background-color: rgba($color: color(purple), $alpha: 0.1);
    }
  }

  &__chart {
    display: flex;
    align-items: center;

    &-box {
      width: calc(100% - 50px);
    }

    &-label {
      width: 50px;
      writing-mode: vertical-lr;
      text-orientation: mixed;
      text-transform: uppercase;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    &-btn {
      border: 0;
      background-color: transparent;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    &-col {
      flex: 0 0 calc(100% / 5);
      width: calc(100% / 5);
      padding-right: 15px;
      padding-left: 15px;

      .view-formatter {
        margin-bottom: 0.625rem;
      }

      .view-formatter p {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
      }
    }
  }

  &__label {
    margin-right: 0.625rem;
    font-size: 14px;
    color: rgba($color: color(black), $alpha: 0.5);
  }

  &__select {
    .form-group {
      min-width: 100px;
      max-width: 340px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.250rem 0;
    width: 100%;
  }

  &__tooltip {
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
