.progress {
  &__box {
    border-radius: 50px;
    height: 10px;
    width: var(--percentage);

    &-green {
      background-color: #2ed47a;
    }
    &-orange {
      background-color: #ffb946;
    }
    &-red {
      background-color: #f7685b;
    }
    &-purple {
      background-color: #6a69e4;
    }
  }
}
