// https://github.com/Hacker0x01/react-datepicker/issues/624

.react-datepicker {
  font-size: 0.625em;
  border: none;
  box-shadow: 0 0 5px #ddd;

  &__triangle {
    &::before {
      border-bottom-color: white !important;
    }
  }
  &__header {
    padding-top: 0.500em;
    background-color: white;
  }
}

.react-datepicker__month {
  margin: 0.250em 0.625em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.188em;
  line-height: 1.188em;
  margin: 0.104em;
}
.react-datepicker__current-month {
  font-size: 0.625em;
}
.react-datepicker__navigation {
  top: 0.500em;
  line-height: 1.063em;
  border: 0.281em solid transparent;
}
.react-datepicker__navigation--previous {
  // border-right-color: #ccc;
  // left: 0.625em;
}
.react-datepicker__navigation--next {
  // border-left-color: #ccc;
  // right: 0.625em;
}

.react-datepicker__input-container {
  cursor: pointer;
  overflow: hidden;
  input {
    border: none;
    transition: box-shadow 0.2s;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(color(dark), 0.4);
      font-size: 0.938rem;
    }
  }
}
