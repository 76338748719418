.extended__report {
  thead {
    tr:first-child {
      background-color: #fff !important;
      color: #212529 !important;
      border: 1px solid #007bff !important;

      th {
        border: 1px solid #007bff !important;
      }
    }
  }
}
