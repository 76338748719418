.software-update {

  &__header {
    color: #fff;
    padding: 69px 30px 50px;
    background: #6a69e4 url('../../img/software-img.png') no-repeat right bottom;
    background-size: 160px 180px;


    .subject-tag {
      border-radius: 4px;
      display: inline-flex;
      padding: 9px 10px;
      font-size: 12px;
      background: #e69137;
      margin-bottom: 23px;
    }
  }

  &_content {
    p, li {
      margin-bottom: 25px;
      font-weight: bold;
    }
  }

}

.software {
  max-width: 600px !important;
}
