.icon {
  &-arrow-right:before {
    content: '\e965';
    color: #9b9b9b;
  }

  &-arrow-left:before {
    content: '\e966';
    color: #9b9b9b;
  }

  &-csv:before {
    content: '\e963';
  }

  &-msword:before {
    content: '\e969';
    color: #4d8af0;
  }

  &-cancelled-invoice:before {
    content: '\e962';
    color: #9b9b9b;
  }

  &-grid:before {
    content: '\e900';
  }

  &-claims:before {
    content: '\e95d';
    color: #4a4a4a;
  }

  &-insurance:before {
    content: '\e95e';
    color: #fff;
  }

  &-red-arrow-down:before {
    content: '\e95f';
    color: #fa4659;
  }

  &-green-arrow-up:before {
    content: '\e960';
    color: #2aa187;
  }

  &-red-x:before {
    content: '\e961';
  }

  &-green-check:before {
    content: '\e964';
  }

  &-invoices:before {
    content: '\e978';
    color: #9b9b9b;
  }

  &-transactions:before {
    content: '\e979';
    color: #fff;
  }

  &-admitted:before {
    content: '\e957';
    color: #9b9b9b;
  }

  &-insight:before {
    content: '\e958';
    color: #fff;
  }

  &-outpatient:before {
    content: '\e959';
    color: #9b9b9b;
  }

  &-pharmacy-presc:before {
    content: '\e95a';
    color: #fff;
  }

  &-sales:before {
    content: '\e95b';
    color: #9b9b9b;
  }

  &-Docket:before {
    content: '\e956';
    color: #9b9b9b;
  }

  &-print:before {
    content: '\e954';
    color: #6a69e4;
  }

  &-success:before {
    content: '\e955';
    color: #6a69e4;
  }

  &-microscope:before {
    content: '\e94f';
    color: #6a69e4;
  }

  &-newspaper:before {
    content: '\e950';
    color: #6a69e4;
  }

  &-notepad:before {
    content: '\e951';
    color: #6a69e4;
  }

  &-progress:before {
    content: '\e952';
    color: #6a69e4;
  }

  &-referral:before {
    content: '\e953';
    color: #6a69e4;
  }

  &-procedure:before {
    content: '\e96b';
    color: #6a69e4;
  }

  &-vital-signs:before {
    content: '\e94e';
    color: #6a69e4;
  }

  &-past-records:before {
    content: '\e946';
    color: #6a69e4;
  }

  &-surgery-records:before {
    content: '\e947';
    color: #6a69e4;
  }

  &-nursing-note:before {
    content: '\e948';
    color: #6a69e4;
  }

  &-physician-note:before {
    content: '\e949';
    color: #6a69e4;
  }

  &-drug-chart:before {
    content: '\e94a';
    color: #6a69e4;
  }

  &-inpatient-admissions:before {
    content: '\e94c';
  }

  &-visit-summary:before {
    content: '\e94d';
    color: #6a69e4;
  }

  &-emergency:before {
    content: '\e93c';
    color: #6a69e4;
  }

  &-admission:before {
    content: '\e944';
    color: #6a69e4;
  }

  &-pill:before {
    content: '\e945';
    color: #6a69e4;
  }

  &-arrow-up:before {
    content: '\e93d';
    color: #6a69e4;
  }

  &-arrow-up-dark:before {
    content: '\e93d';
    color: #000;
  }

  &-camera:before {
    content: '\e93e';
    color: #6a69e4;
  }

  &-go-back:before {
    content: '\e93f';
    color: #4a4a4a;
  }

  &-hmo-corporate:before {
    content: '\e940';
    color: #6a69e4;
  }

  &-info:before {
    content: '\e941';
    color: #6a69e4;
  }

  &-info-orange:before {
    content: '\e941';
    color: #bc9853;
  }

  &-personal-details:before {
    content: '\e942';
    color: #6a69e4;
  }

  &-user-nok:before {
    content: '\e943';
    color: #6a69e4;
  }

  &-fd-appointment:before {
    content: '\e93a';
    color: #9b9b9b;
  }

  &-user:before {
    content: '\e93b';
    color: #9b9b9b;
  }

  &-frontdesk-done:before {
    content: '\e938';
    color: #9b9b9b;
  }

  &-frontdesk-heartbeat:before {
    content: '\e939';
    color: #9b9b9b;
  }

  &-frontdesk-telemed:before {
    content: '\e937';
    color: #6a69e4;
  }

  &-plus:before {
    content: '\e936';
  }

  &-clock:before {
    content: '\e935';
    color: #6a69e4;
  }

  &-edit:before {
    content: '\e934';
  }

  &-calendar:before {
    content: '\e930';
  }

  &-dashboard-fd:before {
    content: '\e931';
  }

  &-transfer-fd .path1:before {
    content: '\e932';
    color: rgb(106, 105, 228);
    opacity: 0.75;
  }

  &-transfer-fd .path2:before {
    content: '\e933';
    margin-left: -1.0341796875em;
    color: rgb(106, 105, 228);
  }

  &-reorder:before {
    content: '\e92e';
    color: #6a69e4;
  }

  &-consumables:before {
    content: '\e92f';
    color: #6a69e4;
  }

  &-transfer:before {
    content: '\e92b';
    color: #9b9b9b;
  }

  &-order:before {
    content: '\e92c';
    color: #9b9b9b;
  }

  &-recall:before {
    content: '\e92d';
    color: #9b9b9b;
  }

  &-deactivated:before {
    content: '\e92a';
  }

  &-option:before {
    content: '\e929';
    color: #d8d8d8;
  }

  &-location:before {
    content: '\e923';
    color: #6a69e4;
  }

  &-mail:before {
    content: '\e925';
    color: #6a69e4;
  }

  &-phone-call:before {
    content: '\e927';
    color: #6a69e4;
  }

  &-trash {
    &:before {
      content: '\e928';
      color: #fa4659;
    }

    &-natural:before {
      content: '\e928';
    }
  }


  &-notification .path1:before {
    content: '\e917';
    color: rgb(255, 255, 255);
  }

  &-notification .path2:before {
    content: '\e918';
    margin-left: -0.9580078125em;
    color: rgb(255, 255, 255);
  }

  &-notification .path3:before {
    content: '\e91b';
    margin-left: -0.9580078125em;
    color: rgb(250, 70, 89);
  }

  &-notification__black .path1:before {
    content: '\e917';
    color: rgb(0, 0, 0);
  }

  &-notification__black .path2:before {
    content: '\e918';
    margin-left: -0.9580078125em;
    color: rgb(0, 0, 0);
  }

  &-notification__black .path3:before {
    content: '\e91b';
    margin-left: -0.9580078125em;
    color: rgb(250, 70, 89);
  }

  &-menu:before {
    content: '\e916';
  }

  &-arrow-down:before {
    content: '\e91c';
    color: #fff;
  }

  &-arrow-down-neutral:before {
    content: '\e91c';
  }

  &-arrow-down-dark:before {
    content: '\e91c';
    color: #000;
  }

  &-appointments-setup:before {
    content: '\e90b';
    color: #fff;
  }

  &-branch-config:before {
    content: '\e90d';
    color: #fff;
  }

  &-branch:before {
    content: '\e907';
    color: #fff;
  }

  &-department:before {
    content: '\e90e';
    color: #fff;
  }

  &-hmo:before {
    content: '\e90f';
    color: #fff;
  }

  &-hospital:before {
    content: '\e910';
    color: #fff;
  }

  &-inventory:before {
    content: '\e911';
    color: #fff;
  }

  &-more:before {
    content: '\e912';
    color: #fff;
  }

  &-patient-list:before {
    content: '\e913';
    color: #fff;
  }

  &-role:before {
    content: '\e914';
    color: #fff;
  }

  &-staff:before {
    content: '\e915';
    color: #fff;
  }

  &-appointment:before {
    content: '\e901';
    color: #fff;
  }

  &-appointments:before {
    content: '\e902';
    color: #fff;
  }

  &-audit-trail:before {
    content: '\e903';
    color: #fff;
  }

  &-backlog:before {
    content: '\e904';
    color: #fff;
  }

  &-caution-inverse:before {
    content: '\e905';
    color: #fff;
  }

  &-caution:before {
    content: '\e906';
    color: #fa4659;
  }

  &-checkmark:before {
    content: '\e908';
    color: #fff;
  }

  &-color-picker:before {
    content: '\e909';
    color: #c4c4c4;
  }

  &-comment:before {
    content: '\e90a';
    color: #c7c7c7;
  }

  &-computer:before {
    content: '\e90c';
    color: #6a69e4;
  }

  &-dashboard-setup:before {
    content: '\e919';
    color: #6a69e4;
  }

  &-file-clip:before {
    content: '\e91a';
    color: #9b9b9b;
  }

  &-filter:before {
    content: '\e91d';
  }

  &-labs-report:before {
    content: '\e91e';
    color: #fff;
  }

  &-list:before {
    content: '\e91f';
  }

  &-logout:before {
    content: '\e920';
    color: #fff;
  }

  &-notifications .path1:before {
    content: '\e921';
    color: rgb(0, 0, 0);
  }

  &-notifications .path2:before {
    content: '\e95c';
    margin-left: -0.7421875em;
    color: rgb(255, 255, 255);
  }

  &-patients:before {
    content: '\e922';
    color: #fff;
  }

  &-search:before {
    content: '\e924';
    color: #9b9b9b;
  }

  &-tasks:before {
    content: '\e926';
    color: #fff;
  }

  &-view-more:before {
    content: '\e967';
    color: #6a69e4;
  }

  &-checkbox-empty:before {
    content: '\e96a';
    color: #979797;
  }

  &-checkbox-purple:before {
    content: '\e968';
    color: #6a69e4;
  }

  &-history {
    &:before {
      content: '\e96c';
      color: #6a69e4;
    }

    &-dark:before {
      content: '\e96d';
      color: #4a4a4a;
    }
  }

  &-medication:before {
    content: '\e96e';
    color: #6a69e4;
  }

  &-credit-card:before {
    content: '\e96f';
  }

  &-help:before {
    content: "\e970";
    color: #fff;
  }

  &-disburse:before {
    content: '\e965';
    color: #6a69e4;
  }

  &-bed:before {
    content: "\e97c";
  }

  &-scalpel:before {
    content: "\e983";
  }

  &-heartbeat:before {
    content: "\e980";
  }

  &-reader:before {
    content: "\e981";
  }

  &-clipboard:before {
    content: "\e97d";
  }

  &-medicine:before {
    content: "\e986";
  }

  &-rotate:before {
    content: "\e988";
  }

  &-alert-triangle:before {
    content: "\e985";
  }

  &-history:before {
    content: "\e96c";
  }

  &-reply:before {
    content: "\e982";
  }

  &-ambulance:before {
    content: "\e94f";
  }

  &-doctor:before {
    content: "\e97e";
  }

  &-prescription:before {
    content: "\e94b";
  }

  &-more-vertical:before {
    content: "\e971";
  }


}

.iconmoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  text-transform: none;
  line-height: 1;
  font-family: 'icomoon', sans-serif !important;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
