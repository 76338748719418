@use 'sass:map';

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

.dark {
  color: color(dark) !important;
}

.grey {
  color: color(grey) !important;
}

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-normal {
  white-space: normal;
}

.gradient-1 {
  background: linear-gradient(269.97deg, #50e3c2 0.01%, #6a69e4 99.97%);
}

.hidden {
  display: none;
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

@each $name, $weight in $font-weights {
  .font-#{$name} {
    font-weight: $weight !important;
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{$side}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{$side}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .px-#{$space} {
    padding-left: #{$space}px !important ;
    padding-right: #{$space}px !important ;
  }
  .py-#{$space} {
    padding-top: #{$space}px !important ;
    padding-bottom: #{$space}px !important ;
  }
}

@each $name, $color in $status-color {
  $background: map-get($status-background, $name);

  .status-#{$name} {
    background: $background !important;
    color: $color !important;
  }
}

@each $key, $value in $themeColors {
  .color-#{$key} {
    color: #{$value};
  }
  .background-#{$key} {
    background-color: #{$value};
  }
  .border-color-#{$key} {
    border-color: #{$value} !important;
  }
}

@each $key, $value in $box-shadows {
  .shadow-#{$key} {
    box-shadow: #{$value};
  }
}

@each $radius in $border-radius {
  .border-tl-#{$radius} {
    border-top-left-radius: #{$radius}px !important;
  }
  .border-tr-#{$radius} {
    border-top-right-radius: #{$radius}px !important;
  }
  .border-bl-#{$radius} {
    border-bottom-left-radius: #{$radius}px !important;
  }
  .border-br-#{$radius} {
    border-bottom-right-radius: #{$radius}px !important;
  }
  .border-r-#{$radius} {
    border-radius: #{$radius}px !important;
  }
}

@for $i from 1 through 20 {
  .flex-basis-#{$i * 5} {
    flex-basis: #{$i * 5 - 1%};
  }
}

@each $breakpoint, $width in $breakpoints {
  @for $i from 1 through 20 {
    .flex-basis-#{$breakpoint}-#{$i * 5} {
      @media (min-width: $width) {
        flex-basis: #{$i * 5 - 1%};
      }
    }
  }
}

@for $i from 1 through 10 {
  .flex-gap-#{$i} {
    gap: #{$i * 10px};
  }

  .flex-column-gap-#{$i} {
    column-gap: #{$i * 10px};
  }

  .flex-row-gap-#{$i} {
    row-gap: #{$i * 10px};
  }
}

.vertical {
  &-lr {
    writing-mode: vertical-lr;
  }
}

.floating {
  &__container {
    border-radius: 4px;
    padding: 0.938rem 0.625rem;
    position: relative;
    border: 1px solid rgba(67, 102, 118, 0.15);
  }

  &__title {
    &-top-left {
      position: absolute;
      top: -10px;
      left: 100px;
      @extend .color-grey;
      @extend .fs-14;
      @extend .bg-white;
      padding: 0 20px;
    }
    &-bottom-right {
      position: absolute;
      bottom: -10px;
      right: 80px;
      @extend .color-grey;
      @extend .fs-14;
      @extend .bg-white;
      padding: 0 20px;
    }
  }
}
