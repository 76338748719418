.calendar-table {
  width: 100%;
  border-collapse: collapse;

  &-container {
    border-radius: 10px;
    border: 1px solid rgba(67, 102, 118, 0.2);
    ::-webkit-scrollbar {
      width: 7px;
      background-color: #f5f5f5;
      opacity: 0.8;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #43667626;
    }

    .table-responsive {
      overflow: auto;
    }
  }

  .name-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: color(light-blue);
    font-size: 40px;
    color: color(purple);
  }

  .name-block {
    display: inline-flex;
    align-items: center;
  }

  .name-text {
    @include font-size(regular);
    display: block;
    color: color(dark);

    &--sm {
      @include font-size(x-small);
      color: color(grey);
    }
  }

  td,
  th {
    vertical-align: middle;
  }

  thead {
    th {
      padding: 20px;
      background-color: rgba($color: color(purple), $alpha: 0.07);
      color: color(grey);

      &:first-of-type {
        border-top-left-radius: 10px;
        color: color(dark);
      }

      &:last-of-type {
        border-top-right-radius: 10px;
      }
    }
  }

  tbody tr:not(:last-child) {
    th,
    td {
      position: relative;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(67, 102, 118, 0.2);
        content: '';
      }
    }

    th::after {
      width: 60%;
    }
  }

  tbody td {
    height: 100px;
  }
}
.calendar-table {
  .round-date {
    border-radius: 50%;
    height: 37px;
    width: 37px;
  }
  &__appointment {
    padding: 8px 10px 0 10px;
    th,
    td {
      width: 120px;
    }
    td {
      border-left: 1px solid rgba(67, 102, 118, 0.2) !important;
      padding: unset !important;
      padding: 4px 1px 0 1px !important;
    }
    &--group {
      position: relative;
      ::before {
        content: '';
        position: absolute;
        top: 0;
        margin-left: -10px;
        height: 3px;
        width: 100%;
        background-color: theme(primary);
        border-radius: 2px;
      }
    }
    &--dot {
      border-radius: 20px;
      cursor: pointer;
      height: 10px;
      width: 20px;
      margin-right: 5px;
      position: relative;
    }
    &--dots {
      margin-top: 24px;
      margin-left: 10px;
    }
  }
}
