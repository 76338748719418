.patients-form {

    &-admit {

        &-notice {
            margin-bottom: 35px;
            border-radius: 5px;
            padding: 10px;
        }

        &-notice p {
            color: color(white);
        }

        &-notice p:first-child {
            color: rgba(255, 255, 255, 0.7);
        }

    }

}
