.system-configuration {
  position: relative;
  display: flex;
  flex-direction: column;

  &__setup {
    position: relative;
    margin: 10px auto 30px;
    max-width: 1025px;

    .toggle-group {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      width: 12.500rem;

      &__text {
        margin-top: 0.188rem;
        margin-right: 3.125rem;
        text-transform: capitalize;
      }
    }

    .form-tags {
      position: relative;

      &__svg {
        position: absolute;
        top: 0.625rem;
        right: 1.250rem;
        border-left: 1px solid #9b9b9b;
        padding: 5px 10px;
        background: #fff !important;
        pointer-events: none;
      }

      &__unit {
        position: absolute;
        top: 0;
        right: 0.938rem;
        width: 9.375rem;
        height: 3.938rem;

        .time-unit {
          @include font-size(medium);
          @include font-weight(regular);
          border-radius: 4px;
          padding: 0.313rem 0.938rem;
          height: 100%;
          background: rgba(106, 105, 228, 0.28);

          p {
            @include abs-center();
            text-align: center;
            text-transform: capitalize;
            color: #4a4a4a;
            user-select: none;
          }
        }
      }
    }
  }

  &__config-setup {
    flex: 1 0 auto;
    padding-top: 1.563rem;
    min-height: 65vh;
  }

  &__page-controls {
    @include respond('xl') {
      width: 1025px;
    }

    flex-shrink: 0;
    margin: 10px auto;
    padding: 0;
    width: 100%;
  }

  .table {
    &-body {
      td:nth-child(2) {
        text-transform: uppercase;
      }
    }
  }

  @include respond('as') {
    .table {
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
    }
  }
}
