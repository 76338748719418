.Toastify {
  &__toast-container {
    width: unset;
    padding: unset;
  }
  &__toast {
    align-items: center;
    border-radius: 5px;
    min-height: 3.125rem;

    &--error {
      background: #e53a40;
    }

    &--success {
      background: #58c9b9;
    }

    &-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.625rem;

      span {
        font-family: $main-font;
        font-size: 1.000rem;
      }

      svg {
        height: 1.250rem;
      }
    }
  }

  &__close-button {
    display: none;
  }
}

.Toastify__toast.custom-react-toastify {
  padding: unset;
  margin: unset;
  min-height: unset;
  box-shadow: unset;
  border-radius: unset;
  margin-bottom: 5px;
  .Toastify__toast-body {
    padding: unset;
    margin: unset;
    span {
      font-size: unset;
    }
  }
}
