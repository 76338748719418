.task-container {
  $this: &;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(67, 102, 118, 0.15);
  padding: 1.250rem;
  background-color: color(white);

  &.blue {
    color: color(cyan);
  }

  &.purple {
    color: theme(primary);

    .card__task__button {
      background: rgba(theme(primary), 0.1);
      color: theme(primary);
    }
  }

  &.yellow {
    color: color(yellow);

    .card__task__button {
      background: rgba(color(yellow), 0.1);
      color: color(yellow);
    }
  }

  &.green {
    color: color(green);

    .card__task__button {
      background: rgba(color(green), 0.1);
      color: color(green);
    }
  }

  &.grey {
    color: color(grey);

    .card__task__button {
      background: rgba(color(grey), 0.1);
      color: color(grey);
    }
  }

  &__body {
    flex: 1;
    padding: 3px;
    width: 100%;
    height: 3.125rem;
    overflow: auto;

    &.empty {
      @include flex-display(column);

      .empty-text {
        margin-top: 0.625rem;
        margin-bottom: 0;
        font-size: 1.125rem;
        font-weight: 500;
        color: color(grey);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.938rem;
    width: 100%;

    &--text {
      flex: 1;
    }

    &--select {
      header {
        @include font-size(small);
        box-shadow: 0 0 3px rgba(color(black), 0.2);
        padding: 0.188rem 1.563rem;
        cursor: pointer;
      }

      main {
        z-index: 2;
        position: absolute;
        top: 1.813rem;
        right: 0.313rem;
        box-shadow: 0 0 2px rgba(151, 151, 151, 0.3) !important;
        padding: 5px;
        background: color(white);

        p {
          @include font-size(small);
        }
      }
    }
  }

  &__items {
    margin-bottom: 0.313rem;
    font-size: 0.750rem;
    font-weight: 500;
    color: color(grey);
  }

  &__title {
    font-size: 1.250rem;
    font-weight: 600;
    color: color(dark);
  }
}
