.add-more-table {
  &-branch-parent {
    margin-top: 10px;
    border-radius: 10px;
    padding-right: 0;
    padding-left: 0;
    max-height: 0;
    overflow: hidden;
    background: rgba(106, 105, 228, 0.01);
    transition: 0.2s ease;

    &.show {
      padding-bottom: 10px;
      max-height: 1000px;
    }

    table {
      margin-bottom: 0 !important;
    }

    th {
      @include font-size(small);
      color: color(dark) !important;

      &:first-child {
        padding-right: 200px !important;
      }
    }
  }

  &-collapsable-parent {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s ease;

    &.show {
      padding-bottom: 10px;
      max-height: 5000px;
      overflow: unset;
    }
  }

  &.product-header {
    thead tr {
      background: #f5f4fd !important;

      th {
        padding: 10px 0 !important;
      }

      th:first-child {
        padding-left: 20px;
      }
    }

    td:first-child {
      padding-left: 20px;
    }

    td {
      border-bottom: 1px solid rgba(155, 155, 155, 0.42);
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &.order-fix {
    thead tr {
      background: unset;
    }

    thead {
      th {
        @include font-size(small);
        color: color(grey) !important;
      }

      tr th {
        padding: 0;
      }
    }

    th:first-child,
    td:first-child {
      padding-left: unset !important;
    }

    td {
      padding-top: 6px !important;
    }

    td:nth-child(2) {
      padding-right: 80px;
    }

    .declined th:nth-child(2) {
      padding-right: 10px;
      text-align: right;
    }

    .order-request {
      position: relative;

      .placeholder {
        margin-top: -8px;
        width: 140px;
      }

      &-div {
        position: absolute;
      }
    }
  }

  &.product-branch {
    th,
    td,
    tr {
      border-bottom: unset;
      background: transparent !important;
    }
  }

  &.two-cols {
    th:last-child {
      text-align: right;
    }

    td:nth-child(2) {
      padding: 20px;
    }
  }

  &-loader {
    position: relative;
    margin: -8px 8px 25px;
    width: 60px;
    height: 60px;

    .loader {
      position: relative;

      &-block {
        min-height: 100%;
      }
    }
  }
}

.add_more_table_container {
  overflow-x: auto;
  width: 100%;
}

.order-table {
  // overflow-x: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  td div {
    width: max-content;
  }
}

.stock-preview-table {
  overflow-x: auto;

  .stock-preview {
    th:first-child,
    td:first-child {
      padding-left: 10px !important;
    }

    td {
      border-bottom: 0;
    }

    .form-group {
      width: 130px !important;
    }
  }
}
