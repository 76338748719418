.report {
  &__empty {
    @media (min-height: 767px) {
      margin-top: 100px;
    }
    @media (min-height: 992px) {
      margin-top: 200px;
    }
  }
}

.report_body_responsive {
  width: 100%;
  overflow-x: auto;
}
