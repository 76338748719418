.calendar-controls {
  display: flex;
  align-items: center;
  border-radius: 7px;
  border: 1px solid rgba(67, 102, 118, 0.2);

  button {
    display: inline-block;
    border: 0;
    padding: 15px;
    background-color: transparent;
    vertical-align: middle;
    font-size: 6px;
    color: color(grey);
    transition: color 0.3s ease;

    &:hover {
      color: color(purple);
    }

    span {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  input[type='text'] {
    border: 0;
    padding: 15px 10px;
    min-width: 250px;
    text-align: center;
    color: color(grey);

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
}
