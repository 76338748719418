.visit {
  &-button {
    padding: 10px 20px 11px;
    border-radius: 4px;
    border: 1.5px solid rgba(67, 102, 118, 0.15);
    background: #FFF;

    &__delete {
      border-radius: 7px;
      border: 1px solid #979797;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.05) inset, 0 4px 5px 0 rgba(0, 0, 0, 0.12);
      display: flex;
      width: 97px;
      background: #fff;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 9px;
    }
  }

  &-list {
    position: absolute;
    width: 100%;
    z-index: 100;
    border-radius: 4px;
    border: 1px solid rgba(67, 102, 118, 0.15);
    background: #FFF;
    height: 200px;
    overflow: auto;
    box-shadow: 0 2px 15px -10px #000;

    &-pill {
      border-radius: 7px;
      background: rgba(245, 166, 35, 0.30);
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 21px;
      border-bottom: 1px solid rgba(67, 102, 118, 0.15);

    }
  }
}
